import qs from 'query-string';
import axios from '../utils/axios.js';
import actionTypes from './actionTypes.js';
import yandexGoal from '../utils/metrica/yandexGoal.js';

export default (value) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.INVOICES__PAY_START });

  const cmsToken = localStorage.getItem('cmsToken');
  const { id, purpose } = getState().invoices.get('current').toJS();
  const clientName = getState().clientData.getIn(['currentDependency', 'PersonalAccount', 'ClientName']);
  const integrationId = getState().clientData.getIn(['currentDependency', 'PersonalAccount', 'EstateObject', 'Project', 'IntegrationId']);

  yandexGoal('CLICK_PAY_BTN', { clientName });

  const options = {
    amount: value,
    housing_complex_id: integrationId,
    description: purpose,
    fio: clientName,
  };

  const query = qs.stringify(options);

  const { data, status } = await axios({
    method: 'get',
    url: `/api/billing/pay_order/${id}?${query}`,
    headers: {
      Authorization: `Bearer ${cmsToken}`
    }
  });

  if (status === 200) {
    window.open(data.formUrl, '_blank');
  }

  return dispatch({ type: actionTypes.INVOICES__PAY_DONE });
};
