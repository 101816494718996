import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { get } from 'lodash';
import classNames from 'classnames';
import { COUNTER_TYPES } from '../../constants/counter.js';
import { chooseCurrentManual } from '../../actions/action.counter.js';

const ManualCounterSelectItem = ({ className, counter, dispatch }) => {
  const handleClick = () => {
    dispatch(chooseCurrentManual(counter));
  };

  const currMeta = get(COUNTER_TYPES, counter.get('service_category'));

  const elementClasses = classNames('manual-counter-select-item', className, currMeta.type);

  return (
    <div className={elementClasses}>
      <div
        className="manual-counter-select-item__box"
        onClick={handleClick}
      >
        <div className="manual-counter-select-item__box-icon" />
        <div className="manual-counter-select-item__box-content">
          <div className="manual-counter-select-item__box-title regular-font">
            №{counter.get('serial_number')}
          </div>
          <div className="manual-counter-select-item__box-current-value">
            Текущие показание:&nbsp;
            {counter.get('manual_counter_value') || "--"}
            &nbsp;
            {currMeta.measure}
          </div>
        </div>
      </div>
    </div>
  );
};

ManualCounterSelectItem.defaultProps = {
  counter: Map(),
  className: 'col-12',
};

export default connect()(ManualCounterSelectItem);
