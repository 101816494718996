import React from 'react';
import { Link } from 'react-router-dom';

const BreadcrumbItem = ({ link, label }) => {
  if (!link) {
    return (
      <li className="breadcrumb-item">
        {label}
      </li>
    );
  }

  return (
    <li className="breadcrumb-item">
      <Link to={link}>{label}</Link>
    </li>
  );
};

export default BreadcrumbItem;
