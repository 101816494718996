import React, { PureComponent } from 'react';
import classNames from 'classnames';
import BreadcrumbItem from './breadcrumbItem.js';

class Breadcrumb extends PureComponent {
  static Item = BreadcrumbItem;

  render () {
    const { className, children } = this.props;

    const elementClasses = classNames('breadcrumb-component', className);

    return (
      <nav className={elementClasses}>
        <ol className="breadcrumb">
          {children}
        </ol>
      </nav>
    );
  }
}

export default Breadcrumb;
