import axios from '../utils/axios.js';
import { OK, BAD_REQUEST } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default (fields) => async (dispatch) => {
  dispatch({ type: actionTypes.REQUEST_CALL__START_SENDING });
  const { data, status } = await axios({
    method: 'post',
    url: `api/mail`,
    data: fields,
  });

  if (status === OK) {
    // dispatch({
    //   type: actionTypes.ADVICE__DATA_RETRIEVED,
    //   payload: data.advices,
    // });
  }
  if (status >= BAD_REQUEST) {
    return dispatch({ type: actionTypes.REQUEST_CALL__ERROR_SENDING });
  }

  return dispatch({ type: actionTypes.REQUEST_CALL__END_SENDING });
};
