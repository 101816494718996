import { padEnd, padStart } from 'lodash';

export default ({ value, afterComa = 2, beforeComa = 1 }) => {
  if (!value || typeof value !== 'number' || value < 0) return '0';
  const factor = Math.pow(10, afterComa);
  const roundValue = Math.ceil(value * factor) / factor;
  let [integer = "0", decimal = "0"] = roundValue.toString().split('.');

  integer = integer.length >= beforeComa ? integer : padStart(integer, beforeComa, '0');
  decimal = decimal.length >= afterComa ? decimal : padEnd(decimal, afterComa, '0');

  return `${integer},${decimal}`;
};
