import axiosIntegration from '../utils/axiosIntegration.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default (PersonalAccountNumber) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.ORDERS_TYPES__LOADING_START });

  const { data, status } = await axiosIntegration({
    method: 'post',
    url: "api/v1/uk/MPIncidentTypesList",
  });

  if (status === OK && data.success === true) {
    dispatch({
      type: actionTypes.ORDERS_TYPES__RETRIEVED,
      payload: data.data,
    });
  }

  return dispatch({ type: actionTypes.ORDERS_TYPES__LOADING_DONE });
};
