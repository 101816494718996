import React from 'react';
import Helmet from 'react-helmet';
import ItemLayouts from '../layouts/itemLayouts.js';
import Header from '../components/header/header.js';

const NewsItemPage = () => {
  return (
    <ItemLayouts>
      <Helmet>
        <title>Политика конфиденциальности</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <Header className="col-12">
            <h2>Политика конфиденциальности</h2>
          </Header>
        </div>
      </div>
      <div className="white-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 content-box">
              <p className="ql-align-justify">На этой странице Вы можете ознакомиться с Политикой конфиденциальности веб-сайта ООО «ПроХоум»
                (далее –«мы», «нам», «нас») в отношении той информации, которую ООО «ПроХоум» может получить с Вашего
                устройства во время использования Вами веб-сайта
                <a href="http://www.prohome.ru" target="_blank" rel="noopener noreferrer"> <u>www.prohome.ru</u> </a>(далее - «Сайт»).
              </p>
              <p className="ql-align-justify">Ваше обращение к Сайту означает безоговорочное Ваше согласие с настоящей политикой и
                указанными в ней условиями обработки информации, получаемой с Вашего устройства. В политике конфиденциальности
                определяется каким образом происходит сбор, хранение и обработка Вашей персональной информации, которая собирается
                при использовании Вами Сайта. Пожалуйста, внимательно прочтите данную Политику. Это поможет Вам принять осознанные
                решения о раскрытии Вашей персональной информации при использовании Сайта. В случае несогласия с Политикой Вы
                можете воздержаться от использования Сайта.
              </p>
              <p className="ql-align-justify">Настоящая политика применима только к Сайту. ООО «ПроХоум» не контролирует и не несет
                ответственность за информацию (последствия ее передачи), переданную Вами третьей стороне, в случае, если такая
                передача была выполнена на ресурсе третьей стороны, на который Вы могли перейти по ссылкам из Сайта.</p>
              <p className="ql-align-justify">ООО «ПроХоум» имеет право вносить изменения в настоящую Политику путем размещения новой
                редакции Политики на сайте ООО «ПроХоум» или в Приложении. Обязанность самостоятельно ознакомиться с актуальной
                редакцией Политики лежит на Пользователе.</p>
              <p className="ql-align-justify">
                <strong>Интеллектуальная собственность и права на использование.</strong>
              </p>
              <p className="ql-align-justify">Мы информируем Вас, и Вы согласны с тем, что Сайт является нашей интеллектуальной
                собственностью и управляется нами. Информационное, графическое, аудио, видео, фото или иное наполнение Сайта и
                размещенные материалы являются нашей интеллектуальной собственностью или собственностью правообладателей, которые
                предоставили нам право размещать указанные материалы на Сайте. Все права на интеллектуальную собственность, в том
                числе авторские, смежные с ними права, исключительные права на фирменные наименования, коммерческие обозначения,
                товарные знаки, знаки обслуживания или иные интеллектуальные права во всех материалах или содержании Сайта
                принадлежат нам или нашим партнёрам.
              </p>
              <p className="ql-align-justify">Мы предоставляем Вам ограниченную, персональную, непередаваемую, не подлежащую
                сублицензии, отзывную лицензию на доступ и использование нашего Сайта и его материалов. Сайт и размещенные в нем
                материалы предназначены исключительно для Вашего некоммерческого использования. Другое использование Сайта или его
                материалов запрещено без нашего предварительного письменного разрешения. Вы согласны не совершать любого, всех или
                нескольких из таких действий как копирование, воспроизведение, передача, распространение, продажа, коммерческое
                использование, изменение, переработка или создание производных материалов от материалов и содержания Сайта.
                Совершение данных действий по отношению к Сайту и его материалам является противозаконным и может предусматривать
                ответственность в соответствии с применимым законодательством. Это ограничение не распространяется на Ваши
                сообщения и объявления на сайте.
              </p>
              <p className="ql-align-justify">Обращаясь к нашему Сайту, Вы принимаете тот факт, что все вопросы, связанные с посещением
                и использованием Сайта, включая соблюдение данной Политики, регулируется в соответствии с правом Российской
                Федерации. При обработке Ваших персональных данных мы руководствуемся положениями Федерального закона от 27 июля
                2006г. № 152-ФЗ «О персональных данных». Более подробную информацию о законодательстве, применимом к Приложению, и
                Ваших правах можно найти на сайте&nbsp;
                <a href="http://www.rg.ru/" target="_blank" rel="noopener noreferrer"><u>www.rg.ru</u></a>
              </p>
              <p className="ql-align-justify">
                <strong>Состав информации, которая может быть получена с устройства пользователя при использовании Сайта и цели ее получения.</strong>
              </p>
              <p className="ql-align-justify">Информация о номере Вашего телефона.</p>
              <p className="ql-align-justify">Номер Вашего телефона используется на Сайте для регистрации в личном кабинете и для
                уведомления об услугах ООО «ПроХоум», существующей задолженности за жилищно-коммунальные и иные услуги,
                информационных сообщениях ООО «ПроХоум». При использовании Сайта данные о номере Вашего телефона копируются на
                серверы ООО «ПроХоум» и могут периодически обновляться.
              </p>
              <p className="ql-align-justify">Информация о местоположении Вашего устройства (на основе данных сети оператора сотовой
                связи и сигналов GPS) используется с целью информирования Вас о местоположении офисов ООО «ПроХоум», терминалов
                для оплаты жилищно-коммунальных услуг и банкоматов, а также о дополнительных услугах партнеров ООО «ПроХоум»,
                доступных Вам вблизи Вашего текущего местоположения.
              </p>
              <p className="ql-align-justify">Фотоизображения, полученные с использованием камеры Вашего устройства, необходимы в
                рамках услуг, реализуемых на Сайте, в том числе для создания и сохранения изображений в профиле Пользователя в
                личном кабинете на Сайте, получения фотоизображений платежных документов и штрих-кодов с целью их распознавания и
                использования для совершений операций по оплате жилищно-коммунальных и прочих услуг, предоставляемых ООО «ПроХоум»,
                передачи ООО «ПроХоум» фотоизображений, сделанных Пользователем.
              </p>
              <p className="ql-align-justify">Информация о версии операционной системы и модели устройства для анализа возможных ошибок
                в работе Сайта и совершенствования работы Сайта передается третьим лицам в обезличенном виде.
              </p>
              <p className="ql-align-justify">Информация об IP-адресе и адресе точки подключения устройства Пользователя для повышения
                Вашей безопасности при обращении к Сайту.
              </p>
              <p className="ql-align-justify"><strong>Мы можем получать следующую персональную информацию</strong>:</p>
              <ol>
                <li>
                  <p className="ql-align-justify">Персональная информация, соотносимая с Вами как с личностью, включая Ваши персональные
                    данные, такие как Ваше имя, домашний адрес, e-mail, телефон и иная подобная информация, которую Вы предоставляете
                    о себе добровольно, регистрируясь в личном кабинете на сайте или в процессе использования сервисов, предлагаемых
                    на сайте.
                  </p>
                </li>
                <li>
                  <p className="ql-align-justify">Данные, которые автоматически передаются Сайту в процессе его использования Вами с
                    помощью установленного на Вашем устройстве программного обеспечения, в том числе Ваш IP-адрес, географическое
                    местоположение, информация о браузере или иной программе, с помощью которой Вы осуществляете доступ к Сайту,
                    технические характеристики оборудования программного обеспечения, используемых Вами в качестве Пользователя, дата
                    и продолжительность посещения Сайта.
                  </p>
                </li>
              </ol>
              <p className="ql-align-justify"><strong>Цели обработки персональной информации.</strong></p>
              <p className="ql-align-justify">Мы собираем и храним только ту персональную информацию, которая необходима для
                использования нашего Сайта, и предоставления Вам сервисов, а также для реализации любой законной цели, указанной в
                данной Политике конфиденциальности.</p>
              <p className="ql-align-justify">Мы обрабатываем собранную персональную информацию в следующих целях:</p>
              <ol>
                <li>
                  <p className="ql-align-justify">Для идентификации, авторизации и аутентификации Вас в качестве пользователя Приложения.</p>
                </li>
                <li>
                  <p className="ql-align-justify">Для предоставления Вам персонализированных Сервисов;</p>
                </li>
                <li>
                  <p className="ql-align-justify">Для общения с Вами, в том числе направления уведомлений и информации, касающейся
                    использования Сервисов, или об изменениях в наших политиках, а также обработки Ваших запросов и заявок;
                  </p>
                </li>
                <li>
                  <p className="ql-align-justify">Для информирования Вас о наших продуктах и услугах или продуктах и услугах наших
                    партнеров или предоставления Вам с учетом Ваших действий на Сайте, информации, которая, по нашему мнению, будет
                    представлять для Вас особый интерес. Вы также позволите нам учесть Ваши индивидуальные предпочтения и
                    предоставить Вам более высокий уровень обслуживания, в частности, мы можем рассылать Вам приглашения на
                    презентации и другие мероприятия, проводимые нами или нашими партнерами, совместно с которыми мы организуем эти
                    мероприятия;
                  </p>
                </li>
                <li>
                  <p className="ql-align-justify">Для таргетирования рекламы;</p>
                </li>
                <li>
                  <p className="ql-align-justify">Для оценки, оптимизации и улучшения качества Сайта, сервисов и удобства их
                    использования в соответствии с Вашими предпочтениями;
                  </p>
                </li>
                <li>
                  <p className="ql-align-justify">Для проведения статистических и подобных исследований на основе анонимных или
                    обезличенных данных;
                  </p>
                </li>
                <li>
                  <p className="ql-align-justify">Для распознавания, исследования и предотвращения действий, которые могут нарушать наши
                    политики или быть незаконными.
                  </p>
                </li>
              </ol>
              <p className="ql-align-justify">Рекламная информация и маркетинговые материалы.</p>
              <p className="ql-align-justify">Мы хотели бы информировать Вас о новостях, акциях, мероприятиях и специальных
                предложениях, которые могли бы быть интересны Вам. Мы ценим Ваше время и внимание, поэтому контролируем рассылку
                рекламной информации и маркетинговых материалов.
              </p>
              <p className="ql-align-justify">Обращаем Ваше внимание, что мы не несем ответственности за действия сторонних
                рекламодателей и продавцов. Мы стремимся работать с надежными компаниями, однако не можем контролировать все их
                действия. Тем не менее, Вы можете сообщать нам о своем опыте взаимодействия с какими-либо третьими лицами, с
                которыми мы работаем, написав нам на адрес электронной почты
                <a href="mailto:info@prohome.ru"> <u>info</u>@<u>prohome.ru</u> </a>
                или позвоним нам по телефону 8-495-800-01-02.
              </p>
              <p className="ql-align-justify"><strong>Условия обработки персональной информации и ее передача другим лицам.</strong></p>
              <p className="ql-align-justify">Мы сохраняем конфиденциальность Вашей персональной информации, кроме случаев
                добровольного предоставления Вами информации о себе для общего доступа неограниченному кругу лиц. Пожалуйста,
                обратите внимание, что Ваша персональная информация, которую Вы самостоятельно размещаете на своей личной странице,
                в комментариях к публикациям им на иных общедоступных Сервисах, может просматриваться другими пользователями Сайта.
              </p>
              <p className="ql-align-justify">Мы не будем передавать Ваши персональные данные другим лицами, за исключением
                ограниченного числа обстоятельств, включая следующие случаи:
              </p>
              <ol>
                <li>
                  <p className="ql-align-justify">Когда Вы даете нам свое согласие на совершение таких действий;</p>
                </li>
                <li>
                  <p className="ql-align-justify">Когда Ваша персональная информация предоставляется другим лицам, с которыми мы имеем
                    партнерские отношения, в том числе в связи с использованием Вами определенного Сервиса, подрядчикам, услугами
                    которых мы пользуемся для осуществления своей деятельности или работы нашего Сайта, или если передача Вашей
                    персональной информации происходит в рамках продажи, уступки или иной передачи нами бизнеса полностью или
                    частично. В этом случае мы потребуем от таких лиц обращаться с Вашей персональной информацией в соответствии с
                    данной Политикой.
                  </p>
                </li>
                <li>
                  <p className="ql-align-justify">Когда мы сотрудничаем с финансовыми учреждениями для совместного предложения Вам
                    продуктов, услуг и сервисов. При этом финансовым учреждениям будет запрещено использовать Вашу персональную
                    информацию иначе, чем для совместного продвижения продуктов, услуг и сервисов.
                  </p>
                </li>
                <li>
                  <p className="ql-align-justify">Когда передача персональной информации предусмотрена применимым законодательством в
                    рамках установленной законодательством процедуры либо производится в соответствии с судебным решением.
                  </p>
                </li>
                <li>
                  <p className="ql-align-justify">Когда это необходимо для обеспечения возможности защиты наших прав и иных законных
                    интересов, прав и законных интересов других лиц или пользователей, при нарушении наших политик или соглашений.
                  </p>
                </li>
                <li>
                  <p className="ql-align-justify">Когда в результате обработки Ваших персональных данных получены обезличенные
                    статистические данные, которые передаются другому лицу для проведения исследования, выполнения работ или оказания
                    услуг по нашему поручению.
                  </p>
                </li>
              </ol>
              <p><strong>Изменение и удаление персональной информации. Хранение данных.</strong></p>
              <p className="ql-align-justify">В любое время Вы можете изменить предоставленную нам персональную информацию полностью
                или частично, воспользовавшись функцией редактирования профиля.
              </p>
              <p className="ql-align-justify">Мы храним Вашу персональную информацию в течение всего периода, на протяжении которого Вы
                являетесь зарегистрированным пользователем нашего Сайта. В случае удаления Вашего аккаунта мы храним Вашу
                персональную информацию столько времени, сколько требуется для достижения обозначенных данной Политикой целей, за
                исключением случаев, когда применимым законодательством предусмотрено обязательное хранение персональной информации
                в течение определенного законом срока.
              </p>
              <p className="ql-align-justify"><strong>Меры, применяемые для защиты персональных данных.</strong></p>
              <p className="ql-align-justify">Мы принимаем организационные и технические меры, которые считаем необходимыми и
                достаточными для обеспечения защиты Вашей персональной информации от неправомерного или случайного доступа,
                уничтожения, изменения, блокирование, копирования, распространения, а также от иных неправомерных действий с ней
                других лиц. Время от времени мы пересматриваем свои процедуры по обеспечению безопасности персональной информации и
                выбираем подходящие новые технологии и методы.
              </p>
              <p className="ql-align-justify">Для целей, изложенных в политике, Общество может привлекать к обработке информации
                Пользователя партнёров, с которыми у Общества заключены соответствующие соглашения о конфиденциальности. Передача
                Обществом партнёрам обезличенных данных об использовании Сайта для целей улучшения работы приложения осуществляется
                на основании договоров с партнёрами.
              </p>
              <p className="ql-align-justify"><strong>Ссылка на другие сайты.</strong></p>
              <p className="ql-align-justify">Настоящая Политика конфиденциальности применимы только к настоящему Сайту и не
                распространяется на какие-либо другие веб-сайты, мобильные приложения, даже если такие веб-сайты, мобильные
                приложения содержат ссылку на наш Сайт. Мы можем указывать ссылки на другие сайты, которые, как мы полагаем, могут
                предоставлять интерес для пользователей нашего Сайта. Тем не менее, мы не можем гарантировать соблюдение высоких
                стандартов конфиденциальности на других веб-сайтах, на которые мы даем ссылки, или нести ответственность за правила
                конфиденциальности таких веб-сайтов. Рекомендуем Вам при переходе на другие сайты ознакомиться с действующими на
                них правилами конфиденциальности.
              </p>
              <p className="ql-align-justify">Мы оставляем за собой право изменять данную Политику полностью или частично с целью
                отражения новых технологий, отраслевых практик, нормативных требований, а также для других целей. Мы призываем Вас
                периодически перечитывать нашу Политику с тем, чтобы быть информированными относительно того, как мы защищаем Вашу
                персональную информацию. В случае внесения изменений в политику на нашем Сайте будет опубликована обновленная
                версия Политики с измененной датой публикации. Это является достаточным уведомлением о внесении изменений в
                Политику конфиденциальности, и других уведомлений об изменениях мы можем Вам не предоставлять. Изменения в Политике
                конфиденциальности вступают в силу с момента их размещения на Сайте. Продолжение использования Вами данного Сайта и
                обращение к нему после публикации изменений в Политике конфиденциальности означает, что Вы тем самым принимаете
                изменения условий Политики конфиденциальности.
              </p>
              <p className="ql-align-justify"><strong>Обратная связь</strong></p>
              <p className="ql-align-justify">Мы приветствуем Ваши отзывы о работе Сайта, нашей деятельности и нашей Политике
                конфиденциальности. Любые вопросы и пожелания можно направлять на адрес
                <a href="mailto:info@prohome.ru"> <u>info</u>@<u>prohome.ru</u> </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ItemLayouts>
  );
};

export default NewsItemPage;
