import React, { useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import uploadFile from '../../actions/api.uploadFile.js';
import FileList from './fileList.js';

const FileLoader = ({ className, dispatch, loading, fileCount }) => {
  const elementClasses = classNames('file-loader', className);

  const input = useRef();

  const openImageInput = () => {
    input.current.click();
  };

  const uploadImage = (event) => {
    const formData = new FormData();
    const elem = event.target;
    const [file] = elem.files;
    if (!file) return null;

    formData.append('file', file);

    return dispatch(uploadFile(formData));
  };

  return (
    <div className={elementClasses}>
      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className="btn btn-sm btn-orange-outline"
            onClick={openImageInput}
            disabled={loading || fileCount >= 5}
          >
            Добавьте до 5 файлов к тексту (jpeg, pdf)
          </button>
          <input
            style={{ display: 'none' }}
            accept="image/*"
            name="image"
            onChange={uploadImage}
            type="file"
            ref={input}
          />
        </div>
        <FileList className="col-12" />
      </div>
    </div>
  );
};

const mapStateToProps = ({ fileStorage }) => ({
  loading: fileStorage.get('loading'),
  fileCount: fileStorage.get('data').size,
});

export default connect(mapStateToProps)(FileLoader);
