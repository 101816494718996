import { isImmutable, fromJS, } from 'immutable';
import actionTypes from '../actions/actionTypes.js';
import { SCROLL_LOAD_LIMIT } from '../constants/pagination.js';

const initialState = {
  loading: false,
  allUploaded: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ORDERS_ACTIVE__MERGE:
      if (!action.payload.length) {
        return state.set('allUploaded', true);
      }

      if (action.payload.length < SCROLL_LOAD_LIMIT) {
        return state
          .set('data', state.get('data').concat(fromJS(action.payload)))
          .set('allUploaded', true);
      }

      return state.set('data', state.get('data').concat(fromJS(action.payload)));

    case actionTypes.ORDERS_ACTIVE__ADD_NEW:
      return state.set('data', state.get('data').unshift(fromJS(action.payload)));

    case actionTypes.ORDERS_ACTIVE__CLEAR:
      return fromJS(initialState);

    case actionTypes.ORDERS_ACTIVE__LOADING_START:
      return state.set('loading', true);

    case actionTypes.ORDERS_ACTIVE__LOADING_DONE:
      return state.set('loading', false);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};
