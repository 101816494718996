import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import getClientData from '../actions/api.getClientData.js';
import getServices from '../actions/api.getServices.js';
import DefaultLayout from '../layouts/defaultLayouts.js';
import DependencySelection from '../components/dependencySelection/dependencySelection.js';
import Header from '../components/header/header.js';
import Spinner from '../components/spinner/spinner.js';
import ServicesGroupList from '../components/servicesGroupList/servicesGroupList.js';
import deleteUK from '../utils/deleteUK.js';

const ServicesGroupPage = ({ dispatch, loading, usersProjects, projectName }) => {
  useEffect(() => {
    dispatch(getClientData());
    dispatch(getServices(usersProjects));
  }, [usersProjects]);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="col-12">
          <Spinner />
        </div>
      );
    }

    return (
      <Fragment>
        <DependencySelection className="col-12" />
        <ServicesGroupList className="col-12" />
      </Fragment>
    );
  };


  return (
    <DefaultLayout>
      <Helmet>
        <title>Услуги - ProHome</title>
        <meta
          name="description"
          content="Услуги"
        />
      </Helmet>
      <Header className="col-12">
        <h4>Услуги управляющей компании</h4>
        <h3>
          В жилом квартале&nbsp;
          {deleteUK(projectName)}
        </h3>
      </Header>
      {renderContent()}
    </DefaultLayout>
  );
};

const mapStateToProps = ({ clientData }) => ({
  usersProjects: clientData.get('usersProjects'),
  projectName: clientData.getIn(['currentDependency', 'PersonalAccount', 'EstateObject', 'Project', 'Name']),
  loading: clientData.get('loading'),
});

export default connect(mapStateToProps)(ServicesGroupPage);
