import React from 'react';
import { Provider } from 'react-redux';
import init from './utils/init.js';
import Routers from './Routers.js';

const App = ({ store, history }) => {
  return (
    <Provider store={store}>
      <Routers history={history} />
    </Provider>
  );
};

export default init(App);
