import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Select from 'react-select';
import chooseDependency from '../../actions/action.chooseDependency.js';
import chooseComplex from '../../actions/action.chooseComplex.js';
import getAdministrativeOffice from '../../actions/api.getAdministrativeOffice.js';
import AdministrativeInfo from './administrativeInfo.js';

const DependencySelection = ({
  className,
  currentDependency,
  currentIntegrationId,
  dependencies,
  dispatch,
}) => {
  useEffect(() => {
    if (currentIntegrationId) {
      dispatch(chooseComplex(currentIntegrationId));
      dispatch(getAdministrativeOffice(currentIntegrationId));
    }
  }, [currentIntegrationId]);

  const elementClasses = classNames("dependency-selection", className);

  const transformDependency = () => {
    return dependencies.toJS().map((dependency) => {
      return {
        value: dependency.Id,
        label: `${dependency.PersonalAccount.EstateObject.SubType}: ${dependency.PersonalAccount.EstateObject.Address}, ${dependency.PersonalAccount.EstateObject.Number}`
      };
    });
  };

  const handleChange = (option) => {
    dispatch(chooseDependency(option.value));
  };

  const findCurrentDependency = () => {
    if (!currentDependency.get('Id')) return null;

    return {
      value: currentDependency.get('Id'),
      label: `${currentDependency.getIn(['PersonalAccount', 'EstateObject', 'SubType'])}: ${currentDependency.getIn(['PersonalAccount', 'EstateObject', 'Address'])}, ${currentDependency.getIn(['PersonalAccount', 'EstateObject', 'Number'])}`,
    };
  };

  return (
    <div className={elementClasses}>
      <div className="dependency-selection__container row no-gutters">
        <AdministrativeInfo currentDependency={currentDependency} />
        <div className="col-12 col-md-12 col-lg-5 dependency-selection__box">
          <div className="dependency-selection__box-title">Объект недвижимости</div>
          <Select
            className="dependency-selection__select"
            classNamePrefix="select"
            name="complex"
            value={findCurrentDependency()}
            placeholder="Выберите свой ЖК"
            options={transformDependency()}
            onChange={handleChange}
            isSearchable={false}
          />
        </div>
      </div>
    </div>
  );
};

DependencySelection.defaultProps = {
  className: 'col-12',
};

const mapStateToProps = ({ clientData }) => ({
  currentIntegrationId: clientData.getIn(['currentDependency', 'PersonalAccount', 'EstateObject', 'Project', 'IntegrationId']),
  currentDependency: clientData.get('currentDependency'),
  dependencies: clientData.get('dependencies'),
});

export default connect(mapStateToProps)(DependencySelection);
