import React from 'react';

const AdviceDocuments = ({ documents }) => {
  return (
    <div className="item-box-document">
      <div className="item-box-document__title">
        Документы
      </div>
      <div className="item-box-document__box">
        {documents.map((documentItem, index) => (
          <a
            key={index}
            href={documentItem.get('url')}
            className="item-box-document__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {documentItem.get('original_name') || "Документ"}
          </a>
        ))}
      </div>
    </div>
  );
};

export default AdviceDocuments;
