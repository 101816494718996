import axios from '../utils/axios.js';
import { login, password } from '../config/index.js';
import { OK } from '../constants/httpStatuses.js';

export default () => async () => {
  const { data, status } = await axios({
    method: 'post',
    url: "admin/login",
    data: {
      login,
      password,
    }
  });

  if (status === OK) {
    await localStorage.setItem('cmsToken', data.token);
  }
};
