import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Chart from 'chart.js';
import HistoryChartPicker from './historyChartPicker.js';

const HistoryChart = ({ className, chartHistory, loading }) => {
  const [chart, setChart] = useState(null);
  const canvasRef = useRef(null);

  const chartInit = () => {
    if (!chartHistory.get('labels').size || (!chartHistory.get('labels').size && loading)) return null;

    const data = chartHistory;

    setChart(new Chart(canvasRef.current, {
      type: 'bar',
      data: {
        labels: data.get('labels').toJS(),
        datasets: [
          {
            label: "Горячая вода",
            data: data.get('hotWater').toJS(),
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
          },
          {
            label: "Холодная вода",
            data: data.get('coldWater').toJS(),
            backgroundColor: 'rgb(14,11,255, 0.2)',
            borderColor: 'rgba(14,11,255, 1)',
            borderWidth: 1
          },
        ]
      },
      options: {
        legend: { display: false },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: (value) => `${value} m3`,
            }
          }],
        },
      }
    }));
  };

  useEffect(() => {
    if (chart) {
      chart.destroy();
      setChart(null);
      chartInit();
    } else {
      chartInit();
    }
  }, [chartHistory, loading]);

  useEffect(() => {
    chartInit();
  }, []);

  const elementClasses = classNames('history-chart history-list', className);

  const renderEmptyBox = () => {
    if (!chartHistory.get('labels').size) {
      return (
        <div className="history-list__empty">
          <div className="history-list__empty-icon" />
          <div className="history-list__empty-text">
            Еще нет показаний счетчиков
          </div>
        </div>
      );
    }
  };

  return (
    <div className={elementClasses}>
      <div className="history-chart__box">
        <HistoryChartPicker />
        {renderEmptyBox()}
        <canvas ref={canvasRef} height="100px" />
      </div>
    </div>
  );
};

const mapStateToProps = ({ counter }) => ({
  loading: counter.get('loading'),
  chartHistory: counter.get('chart'),
});

export default connect(mapStateToProps)(HistoryChart);
