import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import CustomModal from '../customModal/customModal.js';
import PerformerOrderForm from './performerOrderForm.js';
import sendOrder from '../../actions/api.sendOrder.js';
import { clearPerformerOrderFrom } from '../../actions/action.formHelpers.js';

const PerformerOrder = ({
  dispatch,
  serviceGroupId,
  serviceId,
  performerId,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    dispatch(clearPerformerOrderFrom());
    setIsOpen(!isOpen);
  };

  const handleSubmit = (values) => {
    dispatch(sendOrder(values));
  };

  return (
    <Fragment>
      <span
        className="performer-order__btn"
        onClick={toggle}
      >
        Заказать
      </span>
      <CustomModal
        className="performer-order__modal"
        isOpen={isOpen}
        toggle={toggle}
        title="оформление услуги"
        size="lg"
      >
        <PerformerOrderForm
          onSubmit={handleSubmit}
          serviceGroupId={serviceGroupId}
          serviceId={serviceId}
          performerId={performerId}
        />
      </CustomModal>
    </Fragment>
  );
};

export default connect()(PerformerOrder);
