import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import FileLoader from '../fileLoader/fileLoader.js';
import FormDependencySelection from '../formDependencySelection/formDependencySelection.js';
import FormOrderTypeSelection from '../formOrderSelections/formOrderTypeSelection.js';
import FormOrderThemeSelection from '../formOrderSelections/formOrderThemeSelection.js';
import { clearPerformerOrderFrom } from '../../actions/action.formHelpers.js';
import moment from "../../utils/moment";

const validate = (values) => {
  const errors = {};

  if (!values.dependency) {
    errors.dependency = 'Required';
  }

  if (!values.IncidentTypeId) {
    errors.IncidentTypeId = 'Required';
  }

  if (!values.IncidentThemeId) {
    errors.IncidentThemeId = 'Required';
  }

  if (!values.Description) {
    errors.Description = 'Required';
  }

  return errors;
};

const renderTextArea = ({
  input,
  placeholder,
  className,
  meta: { touched, error },
}) => {
  const elementClasses = classNames(className, {
    "is-invalid": touched && error,
  });
  return <textarea className={elementClasses} rows="3" placeholder={placeholder} {...input} />;
};

const renderAddressSelection = (field) => {
  const selectionAddress = (option) => {
    field.input.onChange(option);
  };

  return (
    <FormDependencySelection
      option={field.input.value}
      thingsChanged={selectionAddress}
      error={field.meta.error}
      touched={field.meta.touched}
    />
  );
};

const renderTypeSelection = (field) => {
  const selectionType = (value) => {
    field.input.onChange(value);
  };

  return (
    <FormOrderTypeSelection
      value={field.input.value}
      thingsChanged={selectionType}
      error={field.meta.error}
      touched={field.meta.touched}
      placeholder={field.placeholder}
    />
  );
};

const renderThemeSelection = (field) => {
  const selectionTheme = (value) => {
    field.input.onChange(value);
  };

  return (
    <FormOrderThemeSelection
      value={field.input.value}
      thingsChanged={selectionTheme}
      error={field.meta.error}
      touched={field.meta.touched}
      placeholder={field.placeholder}
      isServices={field.isServices}
    />
  );
};

const NewOrderForm = ({
  handleSubmit,
  sending,
  sendError,
  done,
  className,
  dispatch,
  isServices,
}) => {
  const containerClasses = classNames("modal-form__container", className, {
    "modal-form__container--hidden": done,
  });

  const themesSelectorClasses = classNames("form-group ", {
    "col-12": isServices,
    "col-12 col-md-6": !isServices,
  });

  const clearFormHelper = () => {
    dispatch(clearPerformerOrderFrom());
  };

  const renderDoneBox = () => {
    if (done && sendError) {
      return (
        <div className="modal-form__done-box">
          <div className="modal-form__error-icon" />
          <div className="modal-form__done-title">Ошибка</div>
          <div className="modal-form__form-submit-box">
            <button
              className="btn btn-lg btn-orange"
              onClick={clearFormHelper}
            >
              Повторить
            </button>
          </div>
        </div>
      );
    }

    if (done) {
      return (
        <div className="modal-form__done-box">
          <div className="modal-form__ok-icon" />
          <div className="modal-form__done-title">Ваше сообщение успешно отправлено</div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={containerClasses}>
      <form onSubmit={handleSubmit} className="modal-form row">
        <div className="form-group col-12">
          <Field
            name="dependency"
            component={renderAddressSelection}
            className="form-control form-control-lg"
          />
        </div>
        {
          !isServices
            ? (
              <div className="form-group col-12 col-md-6">
                <Field
                  name="IncidentTypeId"
                  component={renderTypeSelection}
                  className="form-control form-control-lg"
                  placeholder="Тип обращения"
                />
              </div>
            )
            : null
        }

        <div className={themesSelectorClasses}>
          <Field
            name="IncidentThemeId"
            component={renderThemeSelection}
            className="form-control form-control-lg"
            placeholder={isServices ? "Выбрать услугу" : "Чем мы можем Вам помочь?"}
            isServices={isServices}
          />
        </div>

        <div className="form-group col-12">
          <Field
            name="Description"
            component={renderTextArea}
            className="form-control form-control-lg"
            placeholder={isServices ? "Напишите Ваш комментарий" : "Напишите Ваше обращение"}
          />
        </div>
        {
          !isServices
            ? <FileLoader className="form-group col-12" />
            : null
        }
        <div className="col-12 modal-form__footer">
          <div className="form-group modal-form__submit-box">
            <button className="btn btn-lg btn-orange" type="submit" disabled={sending}>
              {isServices ? "Заказать" : "Отправить"}
            </button>
          </div>
        </div>
      </form>
      {renderDoneBox()}
    </div>
  );
};

NewOrderForm.defaultProps = {
  isServices: false,
};

const mapStateToProps = ({ global, clientData, formHelper, orders }, { isServices }) => {
  return {
    initialValues: {
      Date: moment().format("YYYY-MM-DD"),
      ClientId: global.auth.get('clientId'),
      Phone: clientData.get('mobilePhone'),
      IncidentTypeId: isServices ? orders.types.getIn(['service', 'Id']) : undefined,
    },
    sending: formHelper.performerOrderForm.get('sending'),
    done: formHelper.performerOrderForm.get('done'),
    sendError: formHelper.performerOrderForm.get('sendError'),
  };
};

export default connect(mapStateToProps)(reduxForm({ form: 'NewOrderForm', validate })(NewOrderForm));
