import React from 'react';
import {
  YMaps,
  Map,
  Placemark,
  Polygon,
  Polyline
} from 'react-yandex-maps';
import isMobile from '../../utils/isMobile.js';

const MapComponent = ({ latitude, longitude }) => {
  if (latitude && longitude) {
    const mapState = {
      controls: ['zoomControl'],
      center: [latitude, longitude],
      zoom: isMobile() ? 16 : 17
    };

    return (
      <div className="map-component">
        <YMaps>
          <Map
            state={mapState}
            className="map-component__box"
            instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom'); }}
            modules={['control.ZoomControl']}
          >
            <Placemark
              geometry={[latitude, longitude]}
              options={{
                iconLayout: 'default#image',
                iconImageHref: '/static/icons/pin.svg',
                iconImageSize: [59, 65],
              }}
            />
          </Map>
        </YMaps>
      </div>
    );
  }

  const mapState = {
    controls: ['zoomControl'],
    center: [55.723127, 37.650900],
    zoom: isMobile() ? 16 : 17
  };

  return (
    <div className="map-component">
      <YMaps>
        <Map
          state={mapState}
          instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom'); }}
          className="map-component__box"
          modules={['control.ZoomControl']}
        >
          <Placemark
            geometry={[55.723375, 37.650642]}
            options={{
              iconLayout: 'default#image',
              iconImageHref: '/static/icons/pin.svg',
              iconImageSize: [59, 65],
            }}
          />
          <Polygon
            geometry={[[
              [55.724585, 37.649980],
              [55.724800, 37.650098],
              [55.722992, 37.655834],
              [55.722572, 37.655603],
              [55.721509, 37.654426],
              [55.721896, 37.653380],
              [55.721766, 37.653232],
              [55.721972, 37.652605],
              [55.721642, 37.652159],
              [55.722265, 37.650451],
              [55.721732, 37.649716],
              [55.722062, 37.649072],
              [55.722324, 37.649379],
              [55.722237, 37.649635],
              [55.722766, 37.650202],
              [55.722875, 37.649940],
              [55.723888, 37.650937],
              [55.724109, 37.650363],
              [55.724321, 37.650535],
              [55.724509, 37.649942]
            ]]}
            options={{
              fillColor: '#0000001a',
              strokeColor: '#F15F21',
              opacity: 1,
              strokeWidth: 2,
              strokeStyle: 'solid',
            }}
          />
          <Polygon
            geometry={[
              [55.724062, 37.651275],
              [55.723930, 37.651619],
              [55.722855, 37.650462],
              [55.722983, 37.650113],
            ]}
            options={{
              fillColor: '#F15F21',
              outline: false,
              opacity: 0.5,
            }}
          />
          <Polygon
            geometry={[
              [
                [55.724545, 37.649969],
                [55.724526, 37.649776],
                [55.724648, 37.649853],
              ],
              [
                [55.722918, 37.649991],
                [55.722919, 37.649771],
                [55.723034, 37.649889],
              ],
              [
                [55.721541, 37.654461],
                [55.721529, 37.654670],
                [55.721433, 37.654547],
              ]
            ]}
            options={{
              fillColor: '#5a5a5a',
              outline: false,
              opacity: 1,
            }}
          />
          <Polygon
            geometry={[
              [
                [55.723807, 37.651488],
                [55.723815, 37.651708],
                [55.723709, 37.651604],
              ],
              [
                [55.722954, 37.650567],
                [55.722960, 37.650800],
                [55.722836, 37.650666],
              ]
            ]}
            options={{
              fillColor: '#F15F21',
              outline: false,
              opacity: 1,
            }}
          />
          <Polyline
            geometry={[[55.722875, 37.649940], [55.722967, 37.650029]]}
            options={{
              balloonCloseButton: false,
              strokeColor: '#5a5a5a',
              strokeWidth: 3,
              strokeOpacity: 1,
            }}
          />
          <Polyline
            geometry={[[55.724509, 37.649942], [55.724582, 37.649993]]}
            options={{
              balloonCloseButton: false,
              strokeColor: '#5a5a5a',
              strokeWidth: 3,
              strokeOpacity: 1,
            }}
          />
          <Polyline
            geometry={[[55.721509, 37.654426], [55.721591, 37.654512]]}
            options={{
              balloonCloseButton: false,
              strokeColor: '#5a5a5a',
              strokeWidth: 3,
              strokeOpacity: 1,
            }}
          />
          <Polyline
            geometry={[[55.722912, 37.650527], [55.722997, 37.650615]]}
            options={{
              balloonCloseButton: false,
              strokeColor: '#F15F21',
              strokeWidth: 3,
              strokeOpacity: 1,
            }}
          />
          <Polyline
            geometry={[[55.723855, 37.651545], [55.723758, 37.651437]]}
            options={{
              balloonCloseButton: false,
              strokeColor: '#F15F21',
              strokeWidth: 3,
              strokeOpacity: 1,
            }}
          />
        </Map>
      </YMaps>
    </div>
  );
};

export default MapComponent;
