import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import FileItem from './fileItem.js';

const FileList = ({ className, files }) => {
  const elementClasses = classNames('file-list', className);
  return (
    <div className={elementClasses}>
      {files.map((file) => <FileItem key={file.get('id')} file={file} />)}
    </div>
  );
};

const mapStetToProps = ({ fileStorage }) => ({
  files: fileStorage.get('data')
});

export default connect(mapStetToProps)(FileList);
