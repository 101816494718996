import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import ItemLayouts from '../layouts/itemLayouts.js';
import Header from '../components/header/header.js';
import getNewsItem from '../actions/api.getNewsItem.js';
import { clearNewsItem } from '../actions/action.newsItem.js';
import NewsCarousel from '../components/newsCarousel/newsCarousel.js';
import NewsItem from '../components/newsItem/newsItem.js';

const NewsItemPage = ({
  dispatch,
  match,
  currentComplexId,
  currentComplex,
  title,
  description,
}) => {
  useEffect(() => {
    const { params: { id } } = match;
    dispatch(getNewsItem(id));

    return () => dispatch(clearNewsItem());
  }, [match]);

  return (
    <ItemLayouts>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={description}
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <Header className="col-12">
            <h3>Новости</h3>
            <h2>
              {currentComplexId ? `Жилого квартала ${currentComplex.get('Name')}` : 'Жилого квартала'}
            </h2>
          </Header>
        </div>
      </div>
      <div className="white-bg">
        <div className="container">
          <div className="row">
            <NewsItem />
          </div>
        </div>
      </div>

      <NewsCarousel showTitle={false} />
    </ItemLayouts>
  );
};

const mapStateToProps = ({ global, newsItem }) => {
  const currentComplexId = global.complexes.get('current');

  return {
    currentComplexId,
    currentComplex: global.complexes.get('data').find((complex) => currentComplexId === complex.get('IntegrationId')),
    title: newsItem.getIn(['data', 'title']),
    description: newsItem.getIn(['data', 'description']),
  };
};

export default connect(mapStateToProps)(NewsItemPage);
