import React from 'react';
import { connect } from 'react-redux';
import NewsItem from './newsItem.js';
import NewsSkeleton from './newsSkeleton.js';

const NewsList = ({ news, loading, className }) => {
  return (
    <div className={`news-list ${className}`}>
      <div className="row">
        {
          loading && !news.size
            ? <NewsSkeleton className="col-12 col-sm-6 col-md-4" />
            : news.map((newsItem) => <NewsItem key={newsItem.get('id')} newsItem={newsItem} className="col-12 col-sm-6 col-md-4" />)
        }
      </div>
    </div>
  );
};

NewsList.defaultProps = {
  className: 'col-12',
};

const mapStateToProps = ({ news }) => ({
  news: news.get('data'),
  loading: news.get('loading'),
});

export default connect(mapStateToProps)(NewsList);
