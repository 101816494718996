import React from 'react';

const HomeContactInfo = () => {
  return (
    <div className="container home-contact-info">
      <div className="row">
        <div className="col-12">
          <h2 className="home-contact-info__title">Контактная информация</h2>
          <p className="home-contact-info__company-name">ООО ПроХоум</p>
          <p className="home-contact-info__address">
            Адрес: Дербеневская наб., 7 строение 10,
            <br />
            Москва, Россия, 115114
          </p>
          <p className="home-contact-info__phone">
            Контактный центр 24/7:&nbsp;
            <span>+7 495 800 01 02</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeContactInfo;
