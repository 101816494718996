import React from 'react';
import { Link } from 'react-router-dom';

const FaqEmpty = () => {
  return (
    <div className="col-12 faq-list__empty">
      <p>
        По данному жилищному комплексу нет FAQ.
      </p>
      <Link to="/">Вернутся на главную</Link>
    </div>
  );
};

export default FaqEmpty;
