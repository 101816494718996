import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import moment from '../../utils/moment.js';
import { clearPerformerOrderFrom } from '../../actions/action.formHelpers.js';

const validate = (values) => {
  const errors = {};

  if (!values.Date) {
    errors.Date = 'Required';
  }

  if (!values.Description) {
    errors.Description = 'Required';
  }

  return errors;
};

const renderField = ({
  input,
  placeholder,
  type,
  className,
  meta: { touched, error },
}) => {
  const elementClasses = classNames(className, {
    "is-invalid": touched && error,
  });

  return <input {...input} type={type} className={elementClasses} placeholder={placeholder} />;
};

const renderTextArea = ({
  input,
  placeholder,
  className,
  meta: { touched, error },
}) => {
  const elementClasses = classNames(className, {
    "is-invalid": touched && error,
  });
  return <textarea className={elementClasses} rows="3" placeholder={placeholder} {...input} />;
};

const PerformerOrderForm = ({
  dispatch,
  handleSubmit,
  sending,
  done,
  sendError,
  className,
}) => {
  const containerClasses = classNames("modal-form__container", className, {
    "modal-form__container--hidden": done,
  });

  const clearFormHelper = () => {
    dispatch(clearPerformerOrderFrom());
  };

  const renderDoneBox = () => {
    if (done && sendError) {
      return (
        <div className="modal-form__done-box">
          <div className="modal-form__error-icon" />
          <div className="modal-form__done-title">Ошибка</div>
          <div className="modal-form__form-submit-box">
            <button
              className="btn btn-lg btn-orange"
              onClick={clearFormHelper}
            >
              Повторить
            </button>
          </div>
        </div>
      );
    }

    if (done) {
      return (
        <div className="modal-form__done-box">
          <div className="modal-form__ok-icon" />
          <div className="modal-form__done-title">Заявка принята</div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={containerClasses}>
      <form onSubmit={handleSubmit} className="modal-form row">
        <div className="form-group col-12 col-md-6">
          <Field name="Date" component={renderField} type="date" className="form-control form-control-lg" placeholder="Дата предоставления услуги" />
        </div>
        <div className="form-group col-12 col-md-6">
          <Field name="Promocode" component={renderField} type="text" className="form-control form-control-lg" placeholder="Промокод" />
        </div>
        <div className="form-group col-12">
          <Field name="Description" component={renderTextArea} className="form-control form-control-lg" placeholder="Комментарий" />
        </div>
        <div className="col-12 modal-form__footer">
          <div className="form-group modal-form__submit-box">
            <button className="btn btn-lg btn-orange" type="submit" disabled={sending}>Оформить услугу</button>
          </div>
        </div>
      </form>
      {renderDoneBox()}
    </div>
  );
};

const mapStetToProps = ({ clientData, global, formHelper }, { serviceGroupId, serviceId, performerId }) => ({
  initialValues: {
    Date: moment().format("YYYY-MM-DD"),
    IncidentTypeId: serviceGroupId,
    IncidentThemeId: serviceId,
    PerformerId: performerId,
    ClientId: global.auth.get('clientId'),
    Phone: clientData.get('mobilePhone'),
    PersonalAccountId: clientData.getIn(['currentDependency', 'PersonalAccount', 'Id']),
    ProjectId: clientData.getIn(['currentDependency', 'PersonalAccount', 'EstateObject', 'Project', 'Id']),
    EstateObjectId: clientData.getIn(['currentDependency', 'PersonalAccount', 'EstateObject', 'Id']),
  },
  sending: formHelper.performerOrderForm.get('sending'),
  done: formHelper.performerOrderForm.get('done'),
  sendError: formHelper.performerOrderForm.get('sendError'),
});

export default connect(mapStetToProps)(reduxForm({ form: 'PerformerOrderForm', validate })(PerformerOrderForm));
