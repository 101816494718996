import React, { useState } from 'react';
import classNames from 'classnames';
import { Map } from 'immutable';
import ReviewBtn from './reviewBtn.js';
import moment from '../../utils/moment.js';
import textShorter from '../../utils/textShorter.js';

const OrderItem = ({ order }) => {
  const [isOpen, setIsOpen] = useState(false);

  const iconClasses = classNames('order-item__review-icon', {
    "order-item__review-icon--ok": order.get('Mark') === '3',
    "order-item__review-icon--normal": order.get('Mark') === '2',
    "order-item__review-icon--bad": order.get('Mark') === '1',
  });

  const isService = order.getIn(['Type', 'Name']) === 'Заказать услугу';

  const toggle = () => setIsOpen(!isOpen);

  const renderDesc = () => {
    if (!order.get('Description')) return null;

    if (order.get('Description').length <= 90) {
      return (
        <div className="order-item__desc order-item__desc--no-arrow">
          {order.get('Description')}
        </div>
      );
    }

    if (isOpen) {
      return (
        <div className="order-item__desc" onClick={toggle}>
          {order.get('Description')}
        </div>
      );
    }

    return (
      <div className="order-item__desc order-item__desc--short" onClick={toggle}>
        {textShorter(order.get('Description'), 90)}
      </div>
    );
  };

  const renderReviewBox = () => {
    if (order.get('Status') !== 'Закрыто') return null;

    if (order.get('Mark')) {
      return (
        <div className="col-5 order-item__review-box">
          <div className={iconClasses} />
        </div>
      );
    }

    return (
      <div className="col-5 order-item__review-box">
        <ReviewBtn incidentId={order.get('Id')} />
      </div>
    );
  };

  return (
    <div className="order-item col-12">
      <div className="order-item__box">
        <div className="row">
          <div className="col-7">
            <div className="order-item__meta regular-font">
              {moment(order.get('CreationDate')).format('D.MM.YYYY')}
              {order.get('ServiceDeskId') ? <span> № {order.get('ServiceDeskId')}</span> : null}
            </div>
            <div
              className="order-item__title regular-font"
              //onClick={toggle}
            >
              {isService ? 'Услуга' : 'Обращение' }
            </div>
            {
              isService
                ? null
                : (
                  <div className="regular-font">
                    {order.getIn(['Type', 'Name'])}
                  </div>
                )
            }
            <div className="regular-font">
              {order.getIn(['Theme', 'Name'])}
            </div>
          </div>
          {renderReviewBox()}
        </div>
        {renderDesc()}
        <div className="order-item__footer">
          <div className="order-item__status">
            Статус:
            {order.get('Status')}
          </div>
          {
            (order.get('DeadLine') && order.get('Status') === 'Закрыто')
              ? (
                <div className="order-item__date">
                  Дата закрытия:&nbsp;
                  {moment(order.get('DeadLine')).format('D.MM.YYYY')}
                </div>
              )
              : null
          }
        </div>
      </div>
    </div>
  );
};

OrderItem.defaultProps = {
  order: Map(),
};

export default OrderItem;
