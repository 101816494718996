import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import OrdersBtnBox from './ordersBtnBox.js';
import CurrentOrdersList from './currentOrdersList.js';
import HistoryOrderList from './historyOrderList.js';
import MobileSwitch from '../mobileSwitch/mobileSwitch.js';

const Orders = ({ className }) => {
  const elementClasses = classNames('orders', className);

  const renderFirstComponent = () => (<CurrentOrdersList className="col-12 col-lg-6" />);
  const renderSecondComponent = () => (<HistoryOrderList className="col-12 col-lg-6" />);

  return (
    <div className={elementClasses}>
      <div className="row no-gutters">
        <div className="col-12 col-md-4 col-lg-3 orders__sidebar">
          <OrdersBtnBox className="orders__box" />
        </div>
        <div className="col-12 col-md-8 col-lg-9">
          <div className="orders__box">
            <div className="row">
              <MobileSwitch
                renderFirstComponent={renderFirstComponent}
                renderSecondComponent={renderSecondComponent}
                firstLabel="В обработке"
                secondLabel="История"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Orders.defaultProps = {
  className: 'col-12'
};

export default connect()(Orders);
