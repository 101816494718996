import { isImmutable, fromJS, } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
  loading: false,
  lastName: '',
  firstName: '',
  middleName: '',
  email: '',
  mobilePhone: '',
  dependencies: [],
  usersProjects: [],
  currentDependency: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLIENT_DATA__DATA_RETRIEVED:
      return state
        .set('lastName', fromJS(action.payload.lastName))
        .set('firstName', fromJS(action.payload.firstName))
        .set('middleName', fromJS(action.payload.middleName))
        .set('email', fromJS(action.payload.email))
        .set('mobilePhone', fromJS(action.payload.mobilePhone))
        .set('dependencies', fromJS(action.payload.dependencies))
        .set('currentDependency', fromJS(action.payload.dependencies).get(0))
        .set(
          'usersProjects',
          fromJS(action.payload.dependencies).reduce((accum, dependency) => {
            const currProject = dependency.getIn(['PersonalAccount', 'EstateObject', 'Project']);

            if (accum.find((item) => item.get('Id') === currProject.get('Id'))) return accum;
            return accum.push(currProject);
          }, fromJS([]))
        );

    case actionTypes.CLIENT_DATA__CHOOSE_EMAIL:
      return state.set('email', action.payload.email);

    case actionTypes.CLIENT_DATA__LOADING_START:
      return state.set('loading', true);

    case actionTypes.CLIENT_DATA__LOADING_DONE:
      return state.set('loading', false);

    case actionTypes.CLIENT_DATA__CHOOSE_DEPENDENCY:
      return state.set(
        'currentDependency',
        state.get('dependencies').find((dependency) => dependency.get('Id') === action.payload.dependencyId)
      );

    case actionTypes.CLIENT_DATA__CLEAR:
      return fromJS(initialState);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};
