import React from 'react';
import TopNav from '../components/navBar/navBar.js';
import RouterScrollToTop from '../components/routerScrollToTop/routerScrollToTop.js';
import ComplexSelection from '../components/complexSelection/complexSelection.js';
import Footer from '../components/footer/footer.js';

export default ({ children }) => {
  return (
    <React.Fragment>
      <RouterScrollToTop />
      <TopNav white withHeader />
      <div className="container">
        <div className="row">
          <ComplexSelection className="col-12" withInfo />
        </div>
      </div>
      {children}
      <Footer notMargin />
    </React.Fragment>
  );
};
