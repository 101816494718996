import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import getPayLink from '../../actions/api.getPayLink.js';

const PayForm = ({ dispatch, total, redirect }) => {
  const [value, setValue] = useState(total);
  const [fail, setFail] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    if (fail) return undefined;

    dispatch(getPayLink(value));
  };

  const handleChange = (e) => {
    if (e.target.value > total || e.target.value <= 0) {
      setFail(true);
    } else {
      setFail(false);
    }

    setValue(e.target.value);
  };

  const inputClasses = classNames('form-control form-control-lg', { 'is-invalid': fail });

  return (
    <div className="row">
      <div className="form-group col-12">
        <input className={inputClasses} type="number" onChange={handleChange} value={value} />
      </div>
      <div className="col-12">
        <button
          onClick={handleClick}
          className=" btn btn-lg btn-block btn-orange"
          disabled={redirect || fail}
        >
          Оплатить
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ invoices }) => ({
  total: invoices.getIn(['current', 'total_amount_to_pay']),
  redirect: invoices.get('redirect'),
});

export default connect(mapStateToProps)(PayForm);
