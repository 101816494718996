import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import DefaultLayout from '../layouts/defaultLayouts.js';
import Header from '../components/header/header.js';
import DependencySelection from '../components/dependencySelection/dependencySelection.js';
import getClientData from '../actions/api.getClientData.js';
import getInvoices from '../actions/api.getInvoices.js';
import InvoicesList from '../components/invoicesList/invoicesList.js';
import { clearInvoicesData } from '../actions/action.invoices.js';
import Spinner from '../components/spinner/spinner.js';
import Breadcrumb from '../components/breadcrumb/breadcrumb.js';

const InvoicesPage = ({ dispatch, personalAccountNumber, loading }) => {
  useEffect(() => {
    dispatch(getClientData());
    dispatch(getInvoices(personalAccountNumber));

    return () => dispatch(clearInvoicesData());
  }, [personalAccountNumber]);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="col-12">
          <Spinner />
        </div>
      );
    }

    return (
      <Fragment>
        <DependencySelection className="col-12" />
        <Breadcrumb className="col-12">
          <Breadcrumb.Item link="/profile" label="Личный кабинет" />
          <Breadcrumb.Item label="История платежей" />
        </Breadcrumb>
        <InvoicesList className="col-12" personalAccountNumber={personalAccountNumber} />
      </Fragment>
    );
  };

  return (
    <DefaultLayout>
      <Helmet>
        <title>История платежей - ProHome</title>
        <meta
          name="description"
          content="История платежей"
        />
      </Helmet>
      <Header className="col-12">
        <h2>Личный кабинет</h2>
      </Header>
      {renderContent()}
    </DefaultLayout>
  );
};

const mapStateToProps = ({ clientData }) => ({
  personalAccountNumber: clientData.getIn(['currentDependency', 'PersonalAccount', 'Number']),
  loading: clientData.get('loading'),
});

export default connect(mapStateToProps)(InvoicesPage);
