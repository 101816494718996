import React from 'react';

const HomeAdvantages = () => {
  const advantages = [
    {
      image: '/static/icons/home/house.svg',
      text: 'Обеспечение высокого уровня эксплуатации и безопасности.'
    },
    {
      image: '/static/icons/home/technology.svg',
      text: 'Стремление к совершенству и применение эффективных мировых технологий.'
    },
    {
      image: '/static/icons/home/allServices.svg',
      text: 'Новаторский подход в организации комфортной среды для жизни.'
    },
    {
      image: '/static/icons/home/phone.svg',
      text: 'Оперативная, круглосуточная и профессиональная помощь в любом вопросе.'
    },
    // {
    //   image: '/static/icons/home/check.svg',
    //   text: 'Оплата коммунальных платежей и дополнительных услуг без комиссии.'
    // },
    {
      image: '/static/icons/home/service.svg',
      text: 'Качественный персонализированный сервис.'
    },
  ];

  return (
    <div className="home-advantages">
      <div className="container">
        <div className="row justify-content-center">
          {advantages.map((advantage, index) => {
            return (
              <div className="col-12 col-md-6 col-xl-4 home-advantages__item" key={index}>
                <div className="home-advantages__icon" style={{ backgroundImage: `url('${advantage.image}')` }} />
                <div className="home-advantages__text">
                  {advantage.text}
                </div>

              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HomeAdvantages;
