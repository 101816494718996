import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import CustomModal from '../customModal/customModal.js';
import ManualCounterSelect from './manualCounterSelect.js';
import ManualCounterForm from './manualCounterForm.js';
import { clearCurrentManual } from '../../actions/action.counter.js';
import { clearManualCounterForm } from '../../actions/action.formHelpers.js';
import sendManualCounter from '../../actions/api.sendManualCounter.js';

const ManualCounter = ({
  dispatch,
  currentManualCounter,
  sendError,
  done,
  loading,
  message,
  allCounters,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
    dispatch(clearCurrentManual());
    dispatch(clearManualCounterForm());
  };

  const isSelected = !!currentManualCounter.get('serial_number');

  const clearFormHelper = () => dispatch(clearManualCounterForm());

  const handleSubmit = (values) => {
    dispatch(sendManualCounter(values));
  };

  const renderContent = () => {
    if (done && sendError) {
      return (
        <div className="modal-form__container">
          <div className="modal-form__done-box">
            <div className="modal-form__error-icon" />
            <div className="modal-form__done-title">Ошибка</div>
            <div className="modal-form__done-description">{message}</div>
          </div>
          <div className="modal-form__footer">
            <div className="form-group modal-form__submit-box">
              <button
                className="btn btn-lg btn-orange"
                onClick={clearFormHelper}
                type="submit"
              >
                Повторить
              </button>
            </div>
          </div>
        </div>
      );
    }

    if (done) {
      return (
        <div className="modal-form__container">
          <div className="modal-form__done-box">
            <div className="modal-form__ok-icon" />
            <div className="modal-form__done-title">Показания успешно переданы</div>
          </div>
          <div className="modal-form__footer">
            <div className="form-group modal-form__submit-box">
              <button
                className="btn btn-lg btn-orange"
                onClick={clearFormHelper}
                type="submit"
              >
                Продолжить
              </button>
            </div>
          </div>
        </div>
      );
    }

    if (isSelected) {
      return <ManualCounterForm onSubmit={handleSubmit} />;
    }

    return (
      <ManualCounterSelect className="col-12 col-md-6 offset-md-3" />
    );
  };

  if (!allCounters.size || loading) return null;

  return (
    <Fragment>
      <button
        className="btn btn-sm btn-orange-outline btn-manual-counter"
        onClick={toggle}
      >
        Внести показания
      </button>
      <CustomModal
        className="manual-counter-modal"
        isOpen={isOpen}
        toggle={toggle}
        title={isSelected ? "Добавление показаний" : "Выберите счетчик"}
        size="lg"
      >
        {renderContent()}
      </CustomModal>
    </Fragment>
  );
};

ManualCounter.defaultProps = {
  currentManualCounter: Map(),
  allCounters: List(),
};

const mapStetToProps = ({ counter, formHelper }) => ({
  loading: counter.get('loading'),
  allCounters: counter.getIn(['current', 'counters']),
  currentManualCounter: counter.get('currentManualCounter'),
  sending: formHelper.manualCounterForm.get('sending'),
  sendError: formHelper.manualCounterForm.get('sendError'),
  done: formHelper.manualCounterForm.get('done'),
  message: formHelper.manualCounterForm.get('message'),
});

export default connect(mapStetToProps)(ManualCounter);
