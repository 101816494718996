import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { get } from 'lodash';
import classNames from 'classnames';
import { COUNTER_TYPES } from '../../constants/counter.js';

const renderField = ({
  input,
  placeholder,
  type,
  className,
  meta: { touched, error },
}) => {
  const elementClasses = classNames(className, {
    "is-invalid": touched && error,
  });

  return <input {...input} type={type} className={elementClasses} placeholder={placeholder} />;
};

const ManualCounterForm = ({
  handleSubmit,
  sending,
  errMessage,
  className,
  serviceCategory,
  serialNumber,
  manualCurrentValue,
  currentValue,
  CounterValue,
}) => {
  const [error, setError] = useState({ isError: false, isWarning: false, message: '' });

  const checkIsError = () => {
    if (!CounterValue) {
      return setError({
        isError: true,
        isWarning: false,
        message: 'Заполните поле'
      });
    }

    if (CounterValue < currentValue) {
      return setError({
        isError: true,
        isWarning: false,
        message: 'Новые показания должны превышать предудущие показания или быть равными'
      });
    }

    if (CounterValue >= currentValue * 2) {
      return setError({
        isError: false,
        isWarning: true,
        message: 'Введенные показания значительно превышают предыдущие, будьте внимательны'
      });
    }

    return setError({ isError: false, message: '' });
  };

  useEffect(checkIsError, [CounterValue]);

  const renderErrorBox = () => {
    if (error.isError) {
      return (
        <div className="invalid-feedback">
          {error.message}
        </div>
      );
    }

    if (error.isWarning) {
      return (
        <div className="invalid-feedback">
          {error.message}
        </div>
      );
    }

    return null;
  };

  const currMeta = get(COUNTER_TYPES, serviceCategory);

  const containerClasses = classNames("modal-form__container manual-counter-form", className);
  const elementClasses = classNames('manual-counter-select-item col-12 col-md-6 offset-md-3 in-form', currMeta.type);
  const inputClasses = classNames('form-control form-control-lg', {
    "is-invalid": error.isError || error.isWarning
  });

  return (
    <div className={containerClasses}>
      <form onSubmit={handleSubmit} className="modal-form row">
        <div className={elementClasses}>
          <div
            className="manual-counter-select-item__box"
          >
            <div className="manual-counter-select-item__box-icon" />
            <div className="manual-counter-select-item__box-content">
              <div className="manual-counter-select-item__box-title regular-font">
                №{serialNumber}
              </div>
              <div className="manual-counter-select-item__box-current-value">
                Текущие показание:&nbsp;
                {manualCurrentValue}
                &nbsp;
                {currMeta.measure}
              </div>
            </div>

          </div>
        </div>
        <div className="form-group col-12 col-md-6 offset-md-3">
          <Field name="CounterValue" component={renderField} type="text" className={inputClasses} placeholder="Введите показания" />
          {renderErrorBox()}
        </div>
        <div className="col-12 col-md-6 offset-md-3 modal-form__help-err">
          {errMessage}
        </div>
        <div className="col-12 modal-form__footer">
          <div className="form-group modal-form__submit-box">
            <button className="btn btn-lg btn-orange" type="submit" disabled={error.isError}>Сохранить</button>
          </div>
        </div>
      </form>
    </div>
  );
};

const selector = formValueSelector('ManualCounterForm');

const mapStetToProps = (state) => {
  return {
    serialNumber: state.counter.getIn(['currentManualCounter', 'serial_number']),
    currentValue: state.counter.getIn(['currentManualCounter', 'current_counter_value']),
    manualCurrentValue: state.counter.getIn(['currentManualCounter', 'manual_counter_value']),
    serviceCategory: state.counter.getIn(['currentManualCounter', 'service_category']),
    CounterValue: selector(state, 'CounterValue'),
    initialValues: {
      CounterCode: state.counter.getIn(['currentManualCounter', 'service_code']),
      Account: state.clientData.getIn(['currentDependency', 'PersonalAccount', 'Number']),
      Owner: state.clientData.getIn(['currentDependency', 'PersonalAccount', 'ClientId']),
    },
  };
};

export default connect(mapStetToProps)(reduxForm({ form: 'ManualCounterForm' })(ManualCounterForm));
