import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import FormComplexSelection from '../formComplexSelection/formComplexSelection.js';
import { clearRequestCall } from '../../actions/action.formHelpers.js';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  if (!values.phone) {
    errors.phone = 'Required';
  }

  if (!values.complex) {
    errors.complex = 'Required';
  }

  return errors;
};

const renderField = ({
  input,
  placeholder,
  type,
  className,
  meta: { touched, error },
}) => {
  const elementClasses = classNames(className, {
    "is-invalid": touched && error,
  });

  return <input {...input} type={type} className={elementClasses} placeholder={placeholder} />;
};

const renderComplexSelection = (field) => (
  <FormComplexSelection
    value={field.input.value}
    thingsChanged={(value) => field.input.onChange(value)}
    error={field.meta.error}
    touched={field.meta.touched}
  />
);

const RequestCallForm = ({
  handleSubmit,
  sending,
  done,
  sendError,
  dispatch,
  className,
}) => {
  const [isAgree, setIsAgree] = useState(false);

  const clearFormHelper = () => dispatch(clearRequestCall());

  useEffect(() => {
    return clearFormHelper;
  }, []);

  const toggle = () => setIsAgree(!isAgree);

  const containerClasses = classNames("modal-form__container", className, {
    "modal-form__container--hidden": done,
  });

  const renderDoneBox = () => {
    if (done && sendError) {
      return (
        <div className="modal-form__done-box">
          <div className="modal-form__error-icon" />
          <div className="modal-form__done-title">Ошибка</div>
          <div className="modal-form__form-submit-box">
            <button
              className="btn btn-lg btn-orange"
              onClick={clearFormHelper}
            >
              Повторить
            </button>
          </div>
        </div>
      );
    }

    if (done) {
      return (
        <div className="modal-form__done-box">
          <div className="modal-form__ok-icon" />
          <div className="modal-form__done-title">Сообщение отправлено</div>
          <div className="modal-form__form-submit-box">
            <button
              className="btn btn-lg btn-orange"
              onClick={clearFormHelper}
            >
              Повторить
            </button>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={containerClasses}>
      <form onSubmit={handleSubmit} className="modal-form row">
        <div className="form-group col-12 col-md-6">
          <Field name="name" component={renderField} type="text" className="form-control form-control-lg" placeholder="Имя" />
        </div>
        <div className="form-group col-12 col-md-6">
          <Field name="phone" component={renderField} type="text" className="form-control form-control-lg" placeholder="Номер телефона" />
        </div>
        <div className="form-group col-12 col-md-6">
          <Field
            name="complex"
            component={renderComplexSelection}
            className="form-control form-control-lg"
          />
        </div>
        <div className="form-group col-12 col-md-6">
          <Field name="time" component="input" type="text" className="form-control form-control-lg" placeholder="Удобное время звонка" />
        </div>
        <div className="form-group col-12">
          <Field name="message" component="textarea" className="form-control form-control-lg" rows="5" placeholder="Сообщение" />
        </div>
        <div className="col-12 modal-form__footer">
          <div className="form-check modal-form__agree">
            <input className="form-check-input" type="checkbox" id="agree" checked={isAgree} onChange={toggle} />
            <label className="form-check-label" htmlFor="agree">
              Согласен c&nbsp;
              <Link to="/privacy-policy" target="_blank">политикой конфиденциальности</Link>
            </label>
          </div>

          <div className="form-group modal-form__submit-box">
            <button className="btn btn-lg btn-orange" type="submit" disabled={!isAgree || sending}>Заказать звонок</button>
          </div>
        </div>
      </form>
      {renderDoneBox()}
    </div>
  );
};

const mapStateToProps = ({ formHelper }) => ({
  sending: formHelper.requestCallForm.get('sending'),
  done: formHelper.requestCallForm.get('done'),
  sendError: formHelper.requestCallForm.get('sendError'),
});

export default connect(mapStateToProps)(reduxForm({ form: 'RequestCallForm', validate })(RequestCallForm));
