import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  }

  return errors;
};

const renderField = ({
  input,
  placeholder,
  type,
  className,
  meta: { touched, error },
}) => {
  const elementClasses = classNames(className, {
    "is-invalid": touched && error,
  });

  return <input {...input} type={type} className={elementClasses} placeholder={placeholder} />;
};

const EditEmailForm = ({
  handleSubmit,
  sending,
  errMessage,
  className,
}) => {
  const containerClasses = classNames("modal-form__container", className);

  return (
    <div className={containerClasses}>
      <form onSubmit={handleSubmit} className="modal-form row">
        <div className="form-group col-12 col-md-6 offset-md-3">
          <Field name="email" component={renderField} type="text" className="form-control form-control-lg" placeholder="Email" />
        </div>
        <div className="col-12 col-md-6 offset-md-3 modal-form__help-err">
          {errMessage}
        </div>
        <div className="col-12 modal-form__footer">
          <div className="form-group modal-form__submit-box">
            <button className="btn btn-lg btn-orange" type="submit" disabled={sending}>Изменить</button>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStetToProps = ({ formHelper }, { email }) => ({
  initialValues: {
    email
  },
});

export default connect(mapStetToProps)(reduxForm({ form: 'EditEmailForm', validate })(EditEmailForm));
