import React from 'react'

const Header = ({ className, children }) => {
  return (
    <header className={`header ${className}`}>
      {children}
    </header>
  );
};

Header.defaultProps = {
  className: 'col-12'
};

export default Header;
