import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import RouterScrollToTop from '../components/routerScrollToTop/routerScrollToTop.js';
import TopNav from '../components/navBar/navBar.js';

const ErrorLayouts = ({ children, className = '', }) => {
  const layoutClasses = classNames('row', className);

  return (
    <React.Fragment>
      <RouterScrollToTop />
      <TopNav />
      <div className="container container__content">
        <div className={layoutClasses}>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

ErrorLayouts.propTypes = {
  className: PropTypes.string,
};

ErrorLayouts.defaultProps = {
  className: '',
};

export default ErrorLayouts;
