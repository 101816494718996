import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import DefaultLayout from '../layouts/defaultLayouts.js';
import Header from '../components/header/header.js';
import DependencySelection from '../components/dependencySelection/dependencySelection.js';
import getClientData from '../actions/api.getClientData.js';
import getHistory from '../actions/api.getHistory.js';
import { clearCounterHistory } from '../actions/action.counter.js';
import HistoryList from '../components/historyList/historyList.js';
import Spinner from '../components/spinner/spinner.js';
import Breadcrumb from '../components/breadcrumb/breadcrumb.js';

const CounterHistoryPage = ({ dispatch, personalAccountNumber, loading }) => {
  useEffect(() => {
    dispatch(getClientData());
    dispatch(getHistory(personalAccountNumber));

    return () => dispatch(clearCounterHistory());
  }, [personalAccountNumber]);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="col-12">
          <Spinner />
        </div>
      );
    }

    return (
      <Fragment>
        <DependencySelection className="col-12" />
        <Breadcrumb className="col-12 col-md-8">
          <Breadcrumb.Item link="/profile" label="Личный кабинет" />
          <Breadcrumb.Item label="История показаний" />
        </Breadcrumb>
        <div className="col-12 col-md-4 breadcrumb-right-btn-box">
          <Link to="/profile/history/chart" className="breadcrumb-right-btn">
            График
          </Link>
        </div>
        <HistoryList className="col-12" personalAccountNumber={personalAccountNumber} />
      </Fragment>
    );
  };

  return (
    <DefaultLayout>
      <Helmet>
        <title>История показаний - ProHome</title>
        <meta
          name="description"
          content="История показаний"
        />
      </Helmet>
      <Header className="col-12">
        <h2>Личный кабинет</h2>
      </Header>
      {renderContent()}
    </DefaultLayout>
  );
};

const mapStateToProps = ({ clientData }) => ({
  personalAccountNumber: clientData.getIn(['currentDependency', 'PersonalAccount', 'Number']),
  loading: clientData.get('loading'),
});

export default connect(mapStateToProps)(CounterHistoryPage);
