import qs from 'query-string';
import { set } from 'lodash';
import axios from '../utils/axios.js';
import { OK, NOT_FOUND } from '../constants/httpStatuses.js';
import { PAGINATION_LIMIT } from '../constants/pagination.js';
import actionTypes from './actionTypes.js';

export default (currentComplexId, page) => async (dispatch) => {
  dispatch({ type: actionTypes.NEWS__LOADING_START });

  const options = {
    published_to: 'site',
    limit: PAGINATION_LIMIT,
  };

  if (currentComplexId) {
    set(options, 'housing_complexes_ids', currentComplexId);
  }

  const query = qs.stringify(options);

  const { data, status } = await axios({
    method: 'get',
    url: `api/news/page/${page || 1}?${query}`,
  });

  if (status === OK) {
    dispatch({
      type: actionTypes.NEWS__DATA_RETRIEVED,
      payload: data.news,
    });
    dispatch({
      type: actionTypes.PAGINATION__RETRIEVED,
      payload: {
        limit: data.limit,
        page: data.page,
        total: data.total
      },
    });
  }

  if (status === NOT_FOUND) {
    dispatch({
      type: actionTypes.HTTP_STATUS__SET,
      payload: NOT_FOUND,
    });
  }

  return dispatch({ type: actionTypes.NEWS__LOADING_DONE });
};
