import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import ContactLayouts from '../layouts/contactLayouts.js';
import Header from '../components/header/header.js';
import ComplexSelection from '../components/complexSelection/complexSelection.js';
import ContactBox from '../components/contactBox/contactBox.js';
import Map from '../components/map/map.js';

const ContactsPage = ({
  latitude,
  longitude,
  address,
}) => {
  const renderMap = () => {
    return (
      <Map
        latitude={latitude}
        longitude={longitude}
      />
    );
  };

  return (
    <ContactLayouts
      beforeFooterFunc={renderMap}
    >
      <Helmet>
        <title>Контакты - ProHome</title>
        <meta
          name="description"
          content="Команда ProHome — это команда профессионалов с современным подходом к обслуживанию жилой недвижимости и обустройству комфортной среды"
        />
      </Helmet>
      <Header className="col-12">
        <h2>Будем рады помочь</h2>
      </Header>
      <ComplexSelection className="col-12" />
      <ContactBox className="col-12" address={address} />
    </ContactLayouts>
  );
};

const mapStateToProps = ({ administrativeObjects }) => {
  return {
    latitude: administrativeObjects.getIn(['office', 'latitude']),
    longitude: administrativeObjects.getIn(['office', 'longitude']),
    address: administrativeObjects.getIn(['office', 'address']),
  };
};

export default connect(mapStateToProps)(ContactsPage);
