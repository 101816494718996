import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import DefaultLayout from '../layouts/defaultLayouts.js';
import Header from '../components/header/header.js';
import getAdvices from '../actions/api.getAdvices.js';
import AdvicesList from '../components/advicesList/advicesList.js';
import Pagination from '../components/pagination/pagination.js';
import { clearPagination } from '../actions/action.pagination.js';
import findPage from '../utils/findPage.js';

const AdvicesPage = ({ dispatch, page }) => {
  useEffect(() => {
    dispatch(getAdvices(page));
    return () => dispatch(clearPagination());
  }, [page]);

  return (
    <DefaultLayout>
      <Helmet>
        <title>Полезная информация - ProHome</title>
      </Helmet>
      <Header className="col-12">
        <h2>Полезная информация</h2>
      </Header>
      <AdvicesList className="col-12" />
      <Pagination
        route="/usefulinformation"
        className="col-12"
      />
    </DefaultLayout>
  );
};

const mapStateToProps = ({ router }) => ({
  page: findPage(router.location.search),
});

export default connect(mapStateToProps)(AdvicesPage);
