import React from 'react';

const SkeletonLine = ({ className, style }) => {
  return <div className={`skeleton-line ${className}`} style={style} />;
};

SkeletonLine.defaultProps = {
  className: ''
};

export default SkeletonLine;
