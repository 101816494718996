import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
  loading: false,
  office: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMINISTRATIVE_OBJECTS__OFFICE_RETRIEVED:
      if (!action.payload) return state.set('office', fromJS({}));
      return state.set('office', fromJS(action.payload));

    case actionTypes.ADMINISTRATIVE_OBJECTS__LOADING_START:
      return state.set('loading', true);

    case actionTypes.ADMINISTRATIVE_OBJECTS__LOADING_DONE:
      return state.set('loading', false);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};
