import actionTypes from './actionTypes.js';

export const clearCounterHistory = () => {
  return { type: actionTypes.COUNTER__HISTORY_CLEAR };
};

export const clearCounterHistoryChart = () => {
  return { type: actionTypes.COUNTER__HISTORY_CHART_CLEAR };
};

export const updateStartDate = (date) => {
  return {
    type: actionTypes.COUNTER__START_DATE,
    payload: date
  };
};

export const updateEndDate = (date) => {
  return {
    type: actionTypes.COUNTER__END_DATE,
    payload: date
  };
};

export const chooseCurrentManual = (date) => {
  return {
    type: actionTypes.COUNTER__CHOOSE_CURRENT_MANUAL,
    payload: date
  };
};

export const clearCurrentManual = () => {
  return { type: actionTypes.COUNTER__CLEAR_CURRENT_MANUAL };
};
