import axiosIntegration from '../utils/axiosIntegration.js';
import actionTypes from './actionTypes.js';
import { OK } from '../constants/httpStatuses.js';
import { SCROLL_LOAD_LIMIT } from '../constants/pagination.js';

export default (PersonalAccountNumber, page) => async (dispatch, getState) => {
  if (!PersonalAccountNumber) return null;

  if (!page) dispatch({ type: actionTypes.INVOICES__LOADING_START });

  const ClientID = getState().global.auth.get('clientId');

  const { data, status } = await axiosIntegration({
    method: 'post',
    url: "api/v1/uk/Invoice",
    data: {
      ClientID,
      PersonalAccountNumber,
      Archive: true,
      Page: page || 1,
      CountOnPage: SCROLL_LOAD_LIMIT
    }
  });

  if (status === OK && data.success === true) {
    dispatch({
      type: actionTypes.INVOICES__DATA_MERGE,
      payload: data.data,
    });
  }

  return dispatch({ type: actionTypes.INVOICES__LOADING_DONE });
};
