import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import Spinner from '../spinner/spinner.js';
import HistoryItem from './historyItem.js';
import getHistory from '../../actions/api.getHistory.js';

const HistoryList = ({
  className,
  dispatch,
  loading,
  history,
  allUploaded,
  personalAccountNumber,
}) => {
  const elementClasses = classNames('history-list', className);

  const loadFunc = (page) => {
    dispatch(getHistory(personalAccountNumber, page));
  };

  const scrollLoader = () => {
    if (!history.size) return null;
    return <Spinner width={50} key="scrollLoader" />;
  };

  if (loading) {
    return (
      <div className={elementClasses}>
        <Spinner />
      </div>
    );
  }

  const renderBox = () => {
    if (!history.size) {
      return (
        <div className="history-list__empty">
          <div className="history-list__empty-icon" />
          <div className="history-list__empty-text">
            Еще нет показаний счетчиков
          </div>
        </div>
      );
    }

    return (
      <InfiniteScroll
        pageStart={1}
        loadMore={loadFunc}
        hasMore={!allUploaded}
        initialLoad={false}
        loader={scrollLoader()}
      >
        <div className="row">
          {history.map((historyItem, idx) => {
            return <HistoryItem key={idx} historyItem={historyItem} className="col-12" />;
          })}
        </div>
      </InfiniteScroll>
    );
  };

  return (
    <div className={elementClasses}>
      <div className="history-list__box">
        {renderBox()}
      </div>
    </div>
  );
};

const mapStateToProps = ({ counter }) => ({
  loading: counter.get('loading'),
  history: counter.get('history'),
  allUploaded: counter.get('allUploaded'),
});

export default connect(mapStateToProps)(HistoryList);
