import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Dotdotdot from 'react-dotdotdot';
import classNames from 'classnames';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import moment from '../../utils/moment.js';
import getPrevNews from '../../actions/api.getPrevNews.js';
import { clearPagination } from '../../actions/action.pagination.js';
import { clearNews } from '../../actions/action.news.js';
import isMobile from '../../utils/isMobile.js';

const NewsCarousel = ({ dispatch, news, whiteBg, showTitle }) => {
  useEffect(() => {
    dispatch(getPrevNews());
    return () => {
      dispatch(clearPagination());
      dispatch(clearNews());
    };
  }, []);

  const elementClasses = classNames('news-carousel', {
    white: whiteBg
  });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: isMobile() ? 1 : 3,
    slidesToScroll: isMobile() ? 1 : 3,
  };

  if (!news.size) return null;

  return (
    <div className={elementClasses}>
      <div className="container">
        {showTitle ? <h2 className="news-carousel__title">Новости</h2> : null}

        <Slider {...settings}>
          {news.map((newsItem, index) => (
            <div className="news-carousel__item" key={index}>
              <div className="news-carousel__item-box">
                <Link to={`/news/${newsItem.get('id')}`} className="news-carousel__item-title">
                  <Dotdotdot clamp={1}>
                    {newsItem.get('title')}
                  </Dotdotdot>

                </Link>
                <Dotdotdot clamp={2} className="news-carousel__item-desc">
                  {newsItem.get('description')}
                </Dotdotdot>
                <div className="news-carousel__date">
                  {moment(newsItem.get('created_at')).format('D MMMM YYYY г.')}
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="news-carousel__link-box">
          <Link className="news-carousel__link" to="/news">Все новости</Link>
        </div>
      </div>
    </div>
  );
};

NewsCarousel.defaultProps = {
  whiteBg: false,
  showTitle: true,
};

const mapStateToProps = ({ news }) => ({
  news: news.get('data'),
});

export default connect(mapStateToProps)(NewsCarousel);
