import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import complexDirectors from '../../config/complexDirectors.js';

const ComplexInfo = ({ name, address, phoneNumber, integrationId }) => {
  if (!integrationId) return null;

  const directorName = get(complexDirectors, integrationId);

  return (
    <div className="complex-selection__info">
      {address ? <p>Адрес: {address}</p> : null}
      {name ? <p>Управляющая компания — {name}</p> : null}
      {phoneNumber ? <p>Телефон единого контактного центра: {phoneNumber}</p> : null}
      {directorName ? <p>Управляющий: {directorName}</p> : null}
    </div>
  );
};

const mapStetToProps = ({ administrativeObjects }) => {
  return {
    name: administrativeObjects.getIn(['office', 'name']),
    address: administrativeObjects.getIn(['office', 'address']),
    phoneNumber: administrativeObjects.getIn(['office', 'phone_numbers', 0]),
    integrationId: administrativeObjects.getIn(['office', 'housing_complex', 'IntegrationId']),
  };
};

export default connect(mapStetToProps)(ComplexInfo);
