import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from '../../utils/moment.js';
import login from '../../actions/auth.login.js';

class ResendingSms extends Component {
  static defaultProps = {
    time: 30
  };

  constructor(props) {
    super(props);

    this.state = {
      time: props.time
    };
  }

  timerReduction = () => {
    const { time } = this.state;

    this.setState({ time: time - 1 });
    if (time <= 0) clearInterval(this.timer);
  };

  componentDidMount = () => {
    this.timer = setInterval(this.timerReduction, 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  };

  sending = () => {
    const { dispatch, time, data } = this.props;

    dispatch(login(data));

    this.setState({ time });
    this.timer = setInterval(this.timerReduction, 1000);
  };

  render() {
    const { time } = this.state;
    const { className } = this.props;

    const elementClasses = classNames('resending-sms', className);
    const duration = moment.duration(time, 'seconds');

    if (time <= 0) {
      return (
        <div className={elementClasses}>
          <a
            href="#"
            onClick={this.sending}
            className="resending-sms__btn"
          >
            Отправить код повторно
          </a>
        </div>
      );
    }

    return (
      <div className={elementClasses}>
        <p className="resending-sms__disabled">
          {`${duration.minutes()}:${duration.seconds()}`}
        </p>
      </div>
    );
  }
}

const mapStateToProps = ({ formHelper }) => ({
  data: formHelper.authForm.get('data').toJS()
});

export default connect(mapStateToProps)(ResendingSms);
