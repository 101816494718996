const OK = 200;
const NOT_FOUND = 404;
const BAD_REQUEST = 400;
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const SERVER_ERROR = 500;

exports.OK = OK;
exports.NOT_FOUND = NOT_FOUND;
exports.SERVER_ERROR = SERVER_ERROR;
exports.UNAUTHORIZED = UNAUTHORIZED;
exports.FORBIDDEN = FORBIDDEN;
exports.BAD_REQUEST = BAD_REQUEST;

const messages = {};
messages[NOT_FOUND] = 'Not Found';
messages[SERVER_ERROR] = 'Internal Server Error';
messages[UNAUTHORIZED] = 'Unauthorized';
messages[FORBIDDEN] = 'Forbidden';
messages[BAD_REQUEST] = 'Bad request';
exports.messages = messages;
