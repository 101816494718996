import actionTypes from './actionTypes.js';

export const clearRequestCall = () => {
  return { type: actionTypes.REQUEST_CALL__CLEAR };
};

export const clearAuthFrom = () => {
  return { type: actionTypes.AUTH_FORM__CLEAR };
};

export const clearPerformerOrderFrom = () => {
  return { type: actionTypes.PERFORMER_ORDER__CLEAR };
};

export const clearEditEmailFrom = () => {
  return { type: actionTypes.EDIT_EMAIL_FORM__CLEAR };
};

export const clearManualCounterForm = () => {
  return { type: actionTypes.MANUAL_COUNTER_FORM__CLEAR };
};
