import React from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import ErrorLayouts from '../layouts/errorLayouts.js';
import Header from '../components/header/header.js';

const NotFoundPage = ({ loading }) => {
  if (loading) {
    return (
      <ErrorLayouts className="error-page">
        <div className="col-12 error-page__box error-page__box--spinner">
          <ClipLoader
            size={100}
            color="#C7C7CC"
          />
        </div>
      </ErrorLayouts>
    );
  }

  return (
    <ErrorLayouts className="error-page">
      <Helmet>
        <title>404 - ProHome</title>
      </Helmet>
      <Header className="col-12">
        <h1>404</h1>
      </Header>
      <div className="col-12 error-page__box">
        <p>
          Извините, страница не найдена. Ошибка 404.
        </p>
        <Link to="/">
          Вернутся на главную страницу
        </Link>
      </div>
    </ErrorLayouts>
  );
};

const mapStateToProps = ({ global }) => ({
  loading: global.auth.get('loading'),
});

export default connect(mapStateToProps)(NotFoundPage);
