import cookie from 'js-cookie';
import { push } from 'connected-react-router';
import axiosIntegrationAuth from '../utils/axiosIntegrationAuth.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default (Code) => async (dispatch, getState) => {
  const values = getState().formHelper.authForm.get('data').toJS();

  dispatch({ type: actionTypes.AUTH_FORM__START_SENDING });

  const { data, status } = await axiosIntegrationAuth({
    method: 'post',
    url: `api/v1/uk/CodeConfirm`,
    data: { Code, ...values },
  });

  if (status === OK && data.success === true) {
    await localStorage.setItem('ClientID', data.data.ClientID);
    await localStorage.setItem('RefreshToken', data.data.Token.RefreshToken);
    await cookie.set('AccessToken', data.data.Token.AccessToken, { expires: data.data.Token.ExpiresIn / 60 / 60 / 24 });

    dispatch({
      type: actionTypes.AUTH__SUCCESS,
      payload: { clientId: data.data.ClientID }
    });

    dispatch({ type: actionTypes.AUTH_FORM__END_SENDING });

    return dispatch(push('/profile'));
  }

  if (status === OK && data.success === false) {
    dispatch({ type: actionTypes.AUTH_FORM__MESSAGE_RETRIEVED, payload: data.message });

    return dispatch({ type: actionTypes.AUTH_FORM__ERROR_SENDING });
  }

  return dispatch({ type: actionTypes.AUTH_FORM__ERROR_SENDING });
};
