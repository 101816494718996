exports.COUNTER_TYPES = {
  'Холодное водоснабжение': {
    name: 'Холодная вода',
    measure: 'm3',
    type: 'cold-water',
  },
  'Холодное водоснабжение для целей ГВС': {
    name: 'Горячая вода',
    measure: 'm3',
    type: 'hot-water',
  },
  'Электроэнергия': {
    name: 'Электроэнергия',
    measure: 'кВт/ч',
    type: 'electric',
  },
  'Отопление': {
    name: 'Холодное водоснабжение',
    measure: 'm3',
    type: 'heating',
  },
};

exports.COUNTER_COLD_WATER_NAME = 'Холодное водоснабжение';
exports.COUNTER_HOT_WATER_NAME = 'Холодное водоснабжение для целей ГВС';
