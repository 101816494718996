import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
  sending: false,
  done: false,
  sendError: false,
};

/*
 используется при отправке заявок с подряжчиком/заявок/обращений
 у PSN это одна сущность
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PERFORMER_ORDER__START_SENDING:
      return state.set('sending', true);

    case actionTypes.PERFORMER_ORDER__END_SENDING:
      return state
        .set('sending', false)
        .set('done', true);

    case actionTypes.PERFORMER_ORDER__ERROR_SENDING:
      return state
        .set('sending', false)
        .set('done', true)
        .set('sendError', true);

    case actionTypes.PERFORMER_ORDER__CLEAR:
      return fromJS(initialState);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};
