import cookie from 'js-cookie';
import { push } from 'connected-react-router';
import actionTypes from './actionTypes.js';

export default () => async (dispatch) => {
  await localStorage.removeItem('ClientID');
  await localStorage.removeItem('RefreshToken');
  await cookie.remove('AccessToken');

  dispatch({ type: actionTypes.AUTH__FAILED });
  dispatch({ type: actionTypes.CLIENT_DATA__CLEAR });
  dispatch({ type: actionTypes.SERVICES__CLEAR });
  dispatch({ type: actionTypes.INVOICES__CLEAR });
  dispatch({ type: actionTypes.COUNTER__CLEAR });
  return dispatch(push('/'));
};
