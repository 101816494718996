import axios from '../utils/axios.js';
import { OK, NOT_FOUND } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default (adviceId) => async (dispatch) => {
  dispatch({ type: actionTypes.ADVICE__LOADING_START });

  const { data, status } = await axios({
    method: 'get',
    url: `api/advices/${adviceId}`,
  });

  if (status === OK) {
    dispatch({
      type: actionTypes.ADVICE__DATA_RETRIEVED,
      payload: data.advices,
    });
  }

  if (status === NOT_FOUND) {
    dispatch({
      type: actionTypes.HTTP_STATUS__SET,
      payload: NOT_FOUND,
    });
  }

  return dispatch({ type: actionTypes.ADVICE__LOADING_DONE });
};
