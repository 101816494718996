import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from "react-helmet";
import DefaultLayout from '../layouts/defaultLayouts.js';
import Header from '../components/header/header.js';
import getFaq from '../actions/api.getFaq.js';
import FaqList from '../components/faqList/faqList.js';
import ComplexSelection from '../components/complexSelection/complexSelection.js';

const FaqPage = ({ dispatch, currentComplexId, page }) => {
  useEffect(() => {
    dispatch(getFaq(currentComplexId));
  }, [currentComplexId, page]);

  return (
    <DefaultLayout>
      <Helmet>
        <title>FAQ - ProHome</title>
      </Helmet>
      <Header className="col-12">
        <h2>FAQ</h2>
      </Header>
      <ComplexSelection className="col-12" />
      <FaqList className="col-12" />
    </DefaultLayout>
  );
};

const mapStateToProps = ({ global }) => {
  return {
    currentComplexId: global.complexes.get('current'),
  };
};

export default connect(mapStateToProps)(FaqPage);
