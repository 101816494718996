import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import CustomModal from '../customModal/customModal.js';
import RequestCallForm from './requestCallForm.js';
import sendMail from '../../actions/api.sendMail.js';

const RequestCall = ({ dispatch }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const handleSubmit = (value) => {
    const fields = {
      subject: "Заказать звонок",
      name: value.name,
      phone_number: value.phone,
      housing_complex: value.complex,
      is_accept: true,
      message: value.message,
      convenient_time: value.time
    };

    dispatch(sendMail(fields));
  };

  return (
    <Fragment>
      <span className="request-call__btn" onClick={toggle}>Заказать звонок</span>
      <CustomModal
        className="request-call__modal"
        isOpen={isOpen}
        toggle={toggle}
        title="Заказать звонок"
        size="lg"
      >
        <RequestCallForm
          onSubmit={handleSubmit}
          className="request-call__modal-body"
        />
      </CustomModal>
    </Fragment>
  );
};

export default connect()(RequestCall);
