import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducersInit from './reducers/index.js';
import thunk from './utils/reduxMiddlewares/thunk.js';
import changeRouterMiddleware from './utils/reduxMiddlewares/changeRouterMiddleware.js';

const history = createBrowserHistory();

const storeAndHistorySingleton = () => {
  let data = null;

  return (initialState = {}) => {
    if (data) return data;

    data = {
      store: createStore(
        reducersInit(history),
        initialState,
        composeWithDevTools(applyMiddleware(routerMiddleware(history), changeRouterMiddleware(), thunk))
      ),
      history,
    };

    return data;
  };
};

export const initStoreAndHistory = storeAndHistorySingleton();
