import actionTypes from './actionTypes.js';

export const fileDrop = (id) => {
  return {
    type: actionTypes.FILE_STORAGE__DROP_FILE,
    payload: { id }
  };
};

export const allFileDrop = () => {
  return { type: actionTypes.FILE_STORAGE__CLEAR };
};
