import React, { useState } from 'react';
import classNames from 'classnames';

const FaqItem = ({ faqItem }) => {
  const [isOpen, setIsOpen] = useState(false);
  const elementClasses = classNames("col-12 faq-list__item", {
    "is-open": isOpen,
  });

  const toggle = () => setIsOpen(!isOpen);

  const renderAnswer = () => {
    if (!isOpen) return null;

    return <div className="faq-list__item-answer" dangerouslySetInnerHTML={{ __html: faqItem.get('answer') }} />;
  };

  return (
    <div className={elementClasses}>
      <div className="faq-list__item-box">
        <div
          className="faq-list__item-question"
          onClick={toggle}
        >
          {faqItem.get('question')}
        </div>
        {renderAnswer()}
      </div>
    </div>
  );
};

export default FaqItem;
