import axios from '../utils/axios.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default () => async (dispatch) => {
  const { data, status } = await axios({
    method: 'get',
    url: 'api/complexes',
  });

  if (status === OK) {
    dispatch({
      type: actionTypes.COMPLEXES__INIT,
      payload: data.complexes,
    });
  }
};
