import { isImmutable, fromJS, List } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
  loading: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FILE_STORAGE__ADD_FILE:
      return state.set('data', state.get('data').push(fromJS(action.payload)));

    case actionTypes.FILE_STORAGE__DROP_FILE:
      return state.set('data', state.get('data').reduce((accum, file) => {
        if (file.get('id') === action.payload.id) return accum;

        return accum.push(file);
      }, List()));

    case actionTypes.FILE_STORAGE__CLEAR:
      return fromJS(initialState);

    case actionTypes.FILE_STORAGE__LOADING_START:
      return state.set('loading', true);

    case actionTypes.FILE_STORAGE__LOADING_DONE:
      return state.set('loading', false);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};
