import axiosIntegration from '../utils/axiosIntegration.js';
import { OK } from '../constants/httpStatuses.js';
import actionTypes from './actionTypes.js';

export default (values, addToActive = false) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.PERFORMER_ORDER__START_SENDING });

  const files = getState().fileStorage.get('data').map(((file) => {
    return file.get('id');
  })).toJS();

  const { data, status } = await axiosIntegration({
    method: 'post',
    url: "api/v1/uk/MPCreateIncident",
    data: { ...values, FileIDs: files },
  });

  if (status === OK && data.success === true) {
    if (addToActive) {
      dispatch({
        type: actionTypes.ORDERS_ACTIVE__ADD_NEW,
        payload: data.data,
      });
    }

    return dispatch({ type: actionTypes.PERFORMER_ORDER__END_SENDING });
  }

  dispatch({ type: actionTypes.PERFORMER_ORDER__ERROR_SENDING });
  return dispatch({ type: actionTypes.PERFORMER_ORDER__END_SENDING });
};
