import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import DefaultLayout from '../layouts/defaultLayouts.js';
import Header from '../components/header/header.js';
import getNews from '../actions/api.getNews.js';
import NewsList from '../components/newsList/newsList.js';
import ComplexSelection from '../components/complexSelection/complexSelection.js';
import Pagination from '../components/pagination/pagination.js';
import { clearPagination } from '../actions/action.pagination.js';
import findPage from '../utils/findPage.js';

const NewsPage = ({ dispatch, currentComplexId, currentComplex, page }) => {
  useEffect(() => {
    dispatch(getNews(currentComplexId, page));
    return () => dispatch(clearPagination());
  }, [currentComplexId, page]);

  return (
    <DefaultLayout>
      <Helmet>
        <title>Новости - ProHome</title>
        <meta
          name="description"
          content="Новости жилых кварталов"
        />
      </Helmet>
      <Header className="col-12">
        <h3>Новости</h3>
        <h2>
          {currentComplexId ? `Жилого квартала ${currentComplex.get('Name')}` : 'Жилых кварталов'}
        </h2>
      </Header>
      <ComplexSelection className="col-12" />
      <NewsList className="col-12" />
      <Pagination
        route="/news"
        className="col-12"
      />
    </DefaultLayout>
  );
};

const mapStateToProps = ({ global, router }) => {
  const currentComplexId = global.complexes.get('current');

  return {
    currentComplexId,
    currentComplex: global.complexes.get('data').find((complex) => currentComplexId === complex.get('IntegrationId')),
    page: findPage(router.location.search),
  };
};

export default connect(mapStateToProps)(NewsPage);
