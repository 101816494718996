import axios from 'axios';
import { integrationApiHost } from '../config/index.js';

const instance = axios.create({
  baseURL: integrationApiHost,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  validateStatus: function (status) {
    return status >= 200 && status < 500; // возможно понадобится более жесткое условие
  }
});

export default async ({
  method = 'get',
  data = {},
  url,
  headers = {},
}) => {
  return await instance({
    url,
    method,
    data,
    headers: {
      ...headers,
    }
  });
};
