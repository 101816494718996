import React from 'react';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import isMobile from '../../utils/isMobile.js';

const HomeCarousel = () => {
  const itemsForCarousel = [
    {
      img: '/static/images/carousel/dispatcher.jpg',
      mobileImg: '/static/images/carousel/mobile/dispatcher.jpg',
      title: 'Диспетчерская служба',
    },
    {
      img: '/static/images/carousel/cleaning.jpg',
      mobileImg: '/static/images/carousel/mobile/cleaning.jpg',
      title: 'Клининг служба',
    },
    {
      img: '/static/images/carousel/concierge.jpg',
      mobileImg: '/static/images/carousel/mobile/concierge.jpg',
      title: 'Консьерж - сервис',
    },
    {
      img: '/static/images/carousel/service.jpg',
      mobileImg: '/static/images/carousel/mobile/service.jpg',
      title: 'Обслуживание инженерных систем',
    },
    {
      img: '/static/images/carousel/security.jpg',
      mobileImg: '/static/images/carousel/mobile/security.jpg',
      title: 'Охрана',
    },
    {
      img: '/static/images/carousel/settlement.jpg',
      mobileImg: '/static/images/carousel/mobile/settlement.jpg',
      title: 'Расчетный центр',
    },
    {
      img: '/static/images/carousel/additional.jpg',
      mobileImg: '/static/images/carousel/mobile/additional.jpg',
      title: 'Дополнительные услуги',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="home-carousel">
      <Slider {...settings}>
        {itemsForCarousel.map((item, index) => (
          <div className="home-carousel__item" key={index}>
            <div className="home-carousel__img" style={{ backgroundImage: isMobile() ? `url('${item.mobileImg}')` : `url('${item.img}')` }}>
              <div className="home-carousel__item-title">
                {item.title}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>

  );
};

export default HomeCarousel;
