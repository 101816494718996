import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
  loading: false,
  data: [],
  service: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ORDERS_TYPES__RETRIEVED:
      const types = action.payload.Types.reduce((acc, type) => {
        if (type.Name === 'Заказать услугу') return acc.set('service', fromJS(type));

        return acc.set('data', acc.get('data').push(fromJS(type)));
      }, fromJS({ data: [], service: {} }));

      return state
        .set('data', types.get('data'))
        .set('service', types.get('service'));

    case actionTypes.ORDERS_TYPES__LOADING_START:
      return state.set('loading', true);

    case actionTypes.ORDERS_TYPES__LOADING_DONE:
      return state.set('loading', false);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};
