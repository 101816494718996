import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { fileDrop } from '../../actions/action.fileStorage.js';

const FileItem = ({ className, file, dispatch }) => {
  const elementClasses = classNames('file-list__item', className);

  const deleteFile = () => {
    dispatch(fileDrop(file.get('id')));
  };

  return (
    <div className={elementClasses}>
      <div>{file.get('original_name')}</div>
      <div
        className="file-list__item-delete"
        onClick={deleteFile}
      />
    </div>
  );
};

export default connect()(FileItem);
