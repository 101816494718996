export default {
  '34cfcb98-7e85-e611-80f6-005056994571': 'Стрикунов Евгений Анатольевич',
  'f56837c4-3029-e411-acda-005056995954': 'Корнилов Денис Геннадьевич',
  'fd9319b3-ef7a-e411-bba2-005056995954': 'Миронов Дмитрий Викторович',
  '8ce6beca-ef7a-e411-bba2-005056995954': 'Терешин Дмитрий Владимирович',

  // один и тот же, но разные ид на тесте и бое
  'F36837C4-3029-E411-ACDA-005056995954': 'Корнилов Денис Геннадьевич',
  'f36837c4-3029-e411-acda-005056995954': 'Корнилов Денис Геннадьевич',

  '03de6ea7-dc75-11ea-a2db-000c29cf6020': 'Корнилов Денис Геннадьевич',
};
