import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { updatePage } from '../../actions/action.pagination.js';

const Pagination = ({
  route,
  page,
  pageCount,
  className,
  dispatch,
}) => {
  const handleClick = (pageNumber) => {
    return () => {
      dispatch(updatePage(pageNumber));
    };
  };

  const renderSmallPagination = () => {
    const pageItems = [];

    if (page > 1) {
      pageItems.push(
        <li className="page-item page-item__prev" key="prev">
          <Link
            to={`${route}?page=${page - 1}`}
            onClick={handleClick(page - 1)}
            className="page-link"
          >
            <span className="d-none d-md-inline">Предыдущая</span>
            <span className="d-inline d-md-none">&lt;&lt;</span>
          </Link>
        </li>
      );
    }

    for (let currentPage = 1; currentPage <= pageCount; currentPage++) {
      const itemClasses = classNames('page-item', {
        active: currentPage === page
      });

      pageItems.push(
        <li className={itemClasses} key={currentPage}>
          <Link
            to={`${route}?page=${currentPage}`}
            onClick={handleClick(currentPage)}
            className="page-link"
          >
            {currentPage}
          </Link>
        </li>
      );
    }

    if (page < pageCount) {
      pageItems.push(
        <li className="page-item page-item__next" key="next">
          <Link
            to={`${route}?page=${page + 1}`}
            onClick={handleClick(page + 1)}
            className="page-link"
          >
            <span className="d-none d-md-inline">Следующая</span>
            <span className="d-inline d-md-none">&gt;&gt;</span>
          </Link>
        </li>
      );
    }
    return pageItems;
  };

  const renderLargePagination = () => {
    const pageItems = [];
    const SHIFT_INDEX = 3;

    if (page > 1) {
      pageItems.push(
        <li className="page-item page-item__prev" key="prev">
          <Link
            to={`${route}?page=${page - 1}`}
            onClick={handleClick(page - 1)}
            className="page-link"
          >
            <span className="d-none d-md-inline">Предыдущая</span>
            <span className="d-inline d-md-none">&lt;&lt;</span>
          </Link>
        </li>
      );
    }

    if (page > SHIFT_INDEX) {
      pageItems.push(
        <li className="page-item" key="1">
          <Link
            to={`${route}?page=1`}
            onClick={handleClick(1)}
            className="page-link"
          >
            1
          </Link>
        </li>
      );
    }

    if (page > SHIFT_INDEX + 1) {
      pageItems.push(
        <li className="page-item disabled" key="prev-ellipsis">
          <a className="page-link">...</a>
        </li>
      );
    }

    for (let currentPage = page - 2; currentPage <= page + 2; currentPage++) {
      const itemClasses = classNames('page-item', {
        active: currentPage === page
      });

      if (currentPage >= 1 && currentPage <= pageCount) {
        pageItems.push(
          <li className={itemClasses} key={currentPage}>
            <Link
              to={`${route}?page=${currentPage}`}
              onClick={handleClick(currentPage)}
              className="page-link"
            >
              {currentPage}
            </Link>
          </li>
        );
      }
    }

    if (page < pageCount - SHIFT_INDEX) {
      pageItems.push(
        <li className="page-item  disabled" key="next-ellipsis">
          <a className="page-link">...</a>
        </li>
      );
    }

    if (page <= pageCount - SHIFT_INDEX) {
      pageItems.push(
        <li className="page-item" key={pageCount}>
          <Link
            to={`${route}?page=${pageCount}`}
            onClick={handleClick(pageCount)}
            className="page-link"
          >
            {pageCount}
          </Link>
        </li>
      );
    }

    if (page < pageCount) {
      pageItems.push(
        <li className="page-item page-item__next" key="next">
          <Link
            to={`${route}?page=${page + 1}`}
            onClick={handleClick(page + 1)}
            className="page-link"
          >
            <span className="d-none d-md-inline">Следующая</span>
            <span className="d-inline d-md-none">&gt;&gt;</span>
          </Link>
        </li>
      );
    }

    return pageItems;
  };

  if (pageCount <= 1) return null;

  return (
    <nav className={`pagination-box ${className}`}>
      <ul className="pagination">
        {pageCount > 7 ? renderLargePagination() : renderSmallPagination()}
      </ul>
    </nav>
  );
};

Pagination.defaultProps = {
  className: "col-12"
};

const mapStateToProps = ({ global }) => {
  const limit = global.pagination.get('limit');
  const total = global.pagination.get('total');

  return {
    page: global.pagination.get('page'),
    limit,
    total,
    pageCount: Math.ceil(total / limit),
  };
};

export default connect(mapStateToProps)(Pagination);
