import React from 'react';
import classNames from 'classnames';

const ContactBox = ({ address, className }) => {
  const elementClasses = classNames("contact-box", className);

  return (
    <div className={elementClasses}>
      <p className="contact-box__company-name">ООО ПроХоум</p>
      {address ? <p className="contact-box__address">{`Адрес: ${address}`}</p> : null}
      <p className="contact-box__phone">
        Контактный центр 24/7:&nbsp;
        <span>+7 495 800 01 02</span>
      </p>
    </div>
  );
};

ContactBox.defaultProps = {
  className: "col-12"
};

export default ContactBox;
