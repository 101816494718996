import React from 'react';
import SkeletonLine from '../skeletonLine/skeletonLine.js';
import { PAGINATION_LIMIT } from '../../constants/pagination.js';

const NewsSkeleton = ({ className }) => {
  return Array(PAGINATION_LIMIT).fill(true).map((item, idx) => (
    <div className={`news-item ${className}`} key={idx}>
      <div className="news-item__box">
        <SkeletonLine className="news-item__img-skeleton" />
        <div className="news-item__content">
          <SkeletonLine className="news-item__title-skeleton" />
          <SkeletonLine className="news-item__description-skeleton" />
          <SkeletonLine className="news-item__description-skeleton" />
          <SkeletonLine className="news-item__description-skeleton" />
        </div>
      </div>
    </div>
  ));
};

NewsSkeleton.defaultProps = {
  className: 'col-4'
};

export default NewsSkeleton;
