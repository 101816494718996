import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Select from 'react-select';
import { push } from 'connected-react-router';
import ComplexInfo from './complexInfo.js';
import chooseComplex from '../../actions/action.chooseComplex.js';
import getAdministrativeOffice from '../../actions/api.getAdministrativeOffice.js';

const ComplexSelection = ({
  complexes,
  currentComplexId,
  className,
  dispatch,
  withInfo,
  pathname,
}) => {
  const elementClasses = classNames("complex-selection", className);

  const transformComplexes = () => {
    return complexes.toJS().map((complex) => ({ value: complex.IntegrationId, label: complex.Name }));
  };

  const handleChange = (option) => {
    if (pathname && pathname.match(/^\/news/)) dispatch(push('/news'));
    dispatch(chooseComplex(option.value));
    dispatch(getAdministrativeOffice(option.value));
  };

  const findCurrentComplex = () => {
    const currentComplex = complexes.find((complex) => currentComplexId === complex.get('IntegrationId'));

    return currentComplex
      ? { value: currentComplex.get('IntegrationId'), label: currentComplex.get('Name') }
      : null;
  };

  return (
    <div className={elementClasses}>
      <div className="complex-selection__container">
        <Select
          className="complex-selection__select"
          classNamePrefix="select"
          name="complex"
          value={findCurrentComplex()}
          placeholder="Выберите свой ЖК"
          options={transformComplexes()}
          onChange={handleChange}
          isSearchable={false}
        />
        {withInfo ? <ComplexInfo /> : null}
      </div>
    </div>
  );
};

ComplexSelection.defaultProps = {
  className: 'col-12',
  withInfo: false,
};

const mapStateToProps = ({ global, router }) => ({
  complexes: global.complexes.get('data'),
  currentComplexId: global.complexes.get('current'),
  pathname: router.location.pathname,
});

export default connect(mapStateToProps)(ComplexSelection);
