import React from 'react';
import classNames from 'classnames';
import { Map } from 'immutable';
import moment from '../../utils/moment.js';
import CounterValue from './counterValue.js';

const HistoryItem = ({ historyItem, className }) => {
  const elementClasses = classNames('history-item', className);
  const date = moment([historyItem.get('year'), historyItem.get('month') - 1, 1]).format('MMMM, YYYY');

  return (
    <div className={elementClasses}>
      <div className="row no-gutters history-item__box">
        <div className="col-12 col-md-4 col-lg-5 history-item__date-box">
          <div className="history-item__date regular-font">
            {date}
          </div>
        </div>
        <div className="col-12 col-md-8 col-lg-7">
          {
            historyItem.get('counters')
              ? historyItem.get('counters').map((counter, idx) => <CounterValue key={idx} counter={counter} />)
              : null
          }
        </div>
      </div>
    </div>
  );
};

HistoryItem.defaultProps = {
  className: 'col-12',
  historyItem: Map(),
};

export default HistoryItem;
