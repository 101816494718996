import React from 'react';
import { PAGINATION_LIMIT } from '../../constants/pagination.js';
import SkeletonLine from '../skeletonLine/skeletonLine.js';
import NewsSkeleton from "../newsList/newsSkeleton";

const AdvicesSkeleton = ({ className }) => {
  return Array(PAGINATION_LIMIT).fill(true).map((item, idx) => (
    <div className={`advice-item ${className}`} key={idx}>
      <div className="advice-item__box">
        <div className="row no-gutters">
          <div className="col-12 col-md-3 advice-item__img-box">
            <SkeletonLine className="advice-item__img-skeleton" />
          </div>
          <div className="col-12 col-md-9">
            <SkeletonLine className="advice-item__title-skeleton" />
            <SkeletonLine className="advice-item__description-skeleton" />
            <SkeletonLine className="advice-item__description-skeleton" />
            <SkeletonLine className="advice-item__description-skeleton" />
          </div>
        </div>
      </div>
    </div>
  ));
};

NewsSkeleton.defaultProps = {
  className: 'col-12'
};


export default AdvicesSkeleton;
