import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import classNames from 'classnames';
import Spinner from '../spinner/spinner.js';
import ServicesGroupItem from './servicesGroupItem.js';

const ServicesGroupList = ({ className, loading, serviceGroups }) => {
  const elementClasses = classNames('services-group-list', className);

  if (loading) {
    return <Spinner className="col-12" />;
  }

  return (
    <div className={elementClasses}>
      <div className="row">
        {serviceGroups.map((serviceGroup) => (
          <ServicesGroupItem key={serviceGroup.get('Id')} className="col-12 col-md-6 col-lg-4" serviceGroup={serviceGroup} />
        ))}
      </div>
    </div>
  );
};

ServicesGroupList.defaultProps = {
  className: 'col-12',
};

const mapStateToProps = ({ services, clientData }) => {
  const projectId = clientData.getIn(['currentDependency', 'PersonalAccount', 'EstateObject', 'Project', 'Id']);

  return {
    loading: services.get('loading'),
    serviceGroups: services.get('serviceGroups').reduce((accum, serviceGroup) => {
      if (serviceGroup.get('projectsIds').find((currentId) => currentId === projectId)) {
        return accum.push(serviceGroup);
      }

      return accum;
    }, List()),
  };
};

export default connect(mapStateToProps)(ServicesGroupList);
