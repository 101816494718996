import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import DefaultLayout from '../layouts/defaultLayouts.js';
import Header from '../components/header/header.js';
import DependencySelection from '../components/dependencySelection/dependencySelection.js';
import getClientData from '../actions/api.getClientData.js';
import getHistoryForChart from '../actions/api.getHistoryForChart.js';
import { clearCounterHistoryChart } from '../actions/action.counter.js';
import HistoryChart from '../components/historyChart/historyChart.js';
import Spinner from '../components/spinner/spinner.js';
import Breadcrumb from '../components/breadcrumb/breadcrumb.js';

const CounterHistoryPage = ({
  dispatch,
  personalAccountNumber,
  loading,
  startDate,
  endDate,
}) => {
  useEffect(() => {
    dispatch(getClientData());
    dispatch(getHistoryForChart(personalAccountNumber, startDate, endDate));

    return () => dispatch(clearCounterHistoryChart());
  }, [personalAccountNumber, startDate, endDate]);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="col-12">
          <Spinner />
        </div>
      );
    }

    return (
      <Fragment>
        <DependencySelection className="col-12" />
        <Breadcrumb className="col-12">
          <Breadcrumb.Item link="/profile" label="Личный кабинет" />
          <Breadcrumb.Item link="/profile/history" label="История показаний" />
          <Breadcrumb.Item label="График" />
        </Breadcrumb>
        <HistoryChart className="col-12" />
      </Fragment>
    );
  };

  return (
    <DefaultLayout>
      <Helmet>
        <title>История показаний - График - ProHome</title>
        <meta
          name="description"
          content="История показаний - График"
        />
      </Helmet>
      <Header className="col-12">
        <h2>Личный кабинет</h2>
      </Header>
      {renderContent()}
    </DefaultLayout>
  );
};

const mapStateToProps = ({ clientData, counter }) => ({
  personalAccountNumber: clientData.getIn(['currentDependency', 'PersonalAccount', 'Number']),
  loading: clientData.get('loading'),
  startDate: counter.get('startDate'),
  endDate: counter.get('endDate'),
});

export default connect(mapStateToProps)(CounterHistoryPage);
