import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import RouterScrollToTop from '../components/routerScrollToTop/routerScrollToTop.js';
import TopNav from '../components/navBar/navBar.js';
import Footer from '../components/footer/footer.js';

const DefaultLayouts = ({ children, className = '', beforeFooterFunc }) => {
  const layoutClasses = classNames('row', className);

  return (
    <React.Fragment>
      <RouterScrollToTop />
      <TopNav />
      <div className="container container__content">
        <div className={layoutClasses}>
          {children}
        </div>
      </div>
      {beforeFooterFunc ? beforeFooterFunc() : null}
      <Footer notMargin />
    </React.Fragment>
  );
};

DefaultLayouts.propTypes = {
  beforeFooterFunc: PropTypes.func,
  className: PropTypes.string,
};

DefaultLayouts.defaultProps = {
  beforeFooterFunc: null,
  className: '',
};

export default DefaultLayouts;
