import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Map, List } from 'immutable';
import complexDirectors from '../../config/complexDirectors.js';
import deleteUK from '../../utils/deleteUK.js';

const AdministrativeInfo = ({ currentDependency, schedule }) => {
  const renderSchedule = () => {
    if (!schedule.size) return null;

    return (
      <Fragment>
        <div className="dependency-selection__box-title">Часы работы</div>
        {schedule.map((item) => {
          return (
            <p key={item.get('id')}>{`${item.get('days')}: ${item.get('times')}`}</p>
          );
        })}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className="col-12 col-md-6 col-lg-4 dependency-selection__box">
        <div className="dependency-selection__box-title">Жилой комплекс</div>
        <p>{deleteUK(currentDependency.getIn(['PersonalAccount', 'EstateObject', 'Project', 'Name']))}</p>
        <div className="dependency-selection__box-title">Управляющий ЖК</div>
        <p>
          {get(
            complexDirectors,
            currentDependency.getIn(['PersonalAccount', 'EstateObject', 'Project', 'IntegrationId'])
          )}
        </p>
      </div>
      <div className="col-12 col-md-6 col-lg-3 dependency-selection__box">
        {renderSchedule()}
      </div>
    </Fragment>
  );
};

AdministrativeInfo.defaultProps = {
  schedule: List(),
  currentDependency: Map(),
};

const mapStateToProps = ({ administrativeObjects }) => ({
  schedule: administrativeObjects.getIn(['office', 'schedule']),
});


export default connect(mapStateToProps)(AdministrativeInfo);
