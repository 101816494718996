import { yandexMetricaId } from './config.js';
/**
 * Отслеживает цели
 */
export default function yandexGoal(target, params = {}) {
  if (typeof ym === 'function') {
    // eslint-disable-next-line no-undef
    ym(yandexMetricaId, 'reachGoal', target, params);
  } else {
    console.log('Yandex Metrica не загружена:', `ЦЕЛЬ - ${target}`, params);
  }
}
