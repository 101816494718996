import axiosIntegration from '../utils/axiosIntegration.js';
import { OK } from '../constants/httpStatuses.js';
import moment from '../utils/moment.js';
import actionTypes from './actionTypes.js';

export default (personalAccountNumber, startDate, endDate) => async (dispatch, getState) => {
  if (!personalAccountNumber) return null;
  const clientID = getState().global.auth.get('clientId');

  dispatch({ type: actionTypes.COUNTER__LOADING_START });

  const { data, status } = await axiosIntegration({
    method: 'post',
    url: "api/v1/uk/counters/history",
    data: {
      clientID,
      personalAccountNumber,
      startDate: moment(startDate).format('DD.MM.YYYY HH:MM:SS'),
      endDate: moment(endDate).format('DD.MM.YYYY HH:MM:SS')
    }
  });

  if (status === OK && data.success === true) {
    dispatch({
      type: actionTypes.COUNTER__HISTORY_CHART_RETRIEVED,
      payload: data.data,
    });
  }

  return dispatch({ type: actionTypes.COUNTER__LOADING_DONE });
};
