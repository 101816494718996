import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { List } from 'immutable';
import { COUNTER_COLD_WATER_NAME, COUNTER_HOT_WATER_NAME } from '../../constants/counter.js';
import ManualCounterSelectItem from './manualCounterSelectItem.js';

const ManualCounterSelect = ({ className, allCounters }) => {
  const elementClasses = classNames('manual-counter-select', className);

  const filterCounters = () => {
    return allCounters.reduce((accum, counter) => {
      if (
        counter.get('service_category') === COUNTER_COLD_WATER_NAME
        || counter.get('service_category') === COUNTER_HOT_WATER_NAME
      ) {
        if (accum.size <= 0) return accum.push(List([counter]));

        const identicalIndex = accum.findIndex((item) => item.getIn([0, 'installation_site']) === counter.get('installation_site'));

        if (identicalIndex >= 0) {
          return accum.set(identicalIndex, accum.get(identicalIndex).push(counter));
        }
        return accum.push(List([counter]));
      }

      return accum;
    }, List());
  };

  return (
    <div className={elementClasses}>
      {filterCounters().map((group, idx) => {
        return (
          <div className="row" key={idx}>
            <div className="manual-counter-select__title col-12">{group.getIn([0, 'installation_site'])}</div>
            {group.map((item, index) => {
              return <ManualCounterSelectItem key={index} counter={item} className="col-12" />;
            })}
          </div>
        );
      })}
    </div>
  );
};

ManualCounterSelect.defaultProps = {
  allCounters: List(),
  className: 'col-12',
};

const mapStateToProps = ({ counter }) => {
  return {
    allCounters: counter.getIn(['current', 'counters'])
  };
};

export default connect(mapStateToProps)(ManualCounterSelect);
