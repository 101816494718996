import { isImmutable, fromJS } from 'immutable';
import actionTypes from '../actions/actionTypes.js';

const initialState = {
  sending: false,
  done: false,
  sendError: false,
  message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MANUAL_COUNTER_FORM__START_SENDING:
      return state
        .set('sending', true)
        .set('done', false)
        .set('sendError', false)
        .set('message', '');

    case actionTypes.MANUAL_COUNTER_FORM__END_SENDING:
      return state
        .set('sending', false)
        .set('done', true);

    case actionTypes.MANUAL_COUNTER_FORM__MESSAGE_RETRIEVED:
      return state.set('message', action.payload);

    case actionTypes.MANUAL_COUNTER_FORM__ERROR_SENDING:
      return state
        .set('sending', false)
        .set('done', true)
        .set('sendError', true);

    case actionTypes.MANUAL_COUNTER_FORM__CLEAR:
      return fromJS(initialState);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};
