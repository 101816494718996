import { isImmutable, fromJS, List } from 'immutable';
import { has } from 'lodash';
import actionTypes from '../actions/actionTypes.js';
import complexDirectors from '../config/complexDirectors.js';

const initialState = {
  current: null,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPLEXES__INIT:
      return state.set(
        'data',
        fromJS(action.payload).reduce((accum, item) => {
          if (has(complexDirectors, item.get('IntegrationId'))) {
            return accum.push(item.set('Name', item.get('Name').replace('УК ', '')));
          }
          return accum;
        }, List())
      );

    case actionTypes.COMPLEXES__CHOOSE_CURRENT:
      return state.set('current', action.payload);

    default:
      return isImmutable(state) ? state : fromJS(state);
  }
};
