import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import Select from 'react-select';
import classNames from 'classnames';
import { formValueSelector } from 'redux-form';

const FormOrderThemeSelection = ({
  themes,
  typeId,
  value,
  thingsChanged,
  error,
  touched,
  placeholder,
}) => {
  const selectClasses = classNames("form-complex-selection__select", {
    "is-invalid": touched && error,
  });

  useEffect(() => {
    thingsChanged(undefined);
  }, [typeId]);

  const transformThemes = () => {
    return themes.map((theme) => ({
      value: theme.get('Id'),
      label: theme.get('Name'),
    }));
  };

  const handleChange = (option) => {
    thingsChanged(option.value);
  };

  const findCurrentTheme = () => {
    const currentTheme = themes.find((theme) => {
      if (!value) return null;
      return value === theme.get('Id');
    });

    return currentTheme
      ? { value: currentTheme.get('Id'), label: currentTheme.get('Name') }
      : null;
  };

  return (
    <div className="form-complex-selection__container">
      <Select
        className={selectClasses}
        classNamePrefix="select-form-lg"
        value={findCurrentTheme()}
        placeholder={placeholder}
        options={transformThemes()}
        onChange={handleChange}
        isSearchable={false}
        isDisabled={!typeId}
      />
    </div>
  );
};

FormOrderThemeSelection.defultProps = {
  themes: List(),
};

const selector = formValueSelector('NewOrderForm');

const mapStateToProps = (state, { isServices }) => {
  const typeId = selector(state, 'IncidentTypeId');

  const currType = isServices
    ? state.orders.types.get('service')
    : state.orders.types.get('data').find((type) => type.get('Id') === typeId);

  return {
    typeId,
    themes: currType ? currType.get('Themes') : List(),
  };
};

export default connect(mapStateToProps)(FormOrderThemeSelection);
