import React from 'react';
import TopNav from '../components/navBar/navBar.js';
import RouterScrollToTop from '../components/routerScrollToTop/routerScrollToTop.js';
import Footer from '../components/footer/footer.js';

export default ({ children }) => {
  return (
    <React.Fragment>
      <RouterScrollToTop />
      <TopNav />
      <div className="container__content">
        {children}
      </div>
      <Footer />
    </React.Fragment>
  );
};
