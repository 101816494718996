import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from '../../utils/moment.js';
import { NOT_RESIDENTIAL_TYPE } from '../../constants/estateObjects.js';
import getCounter from '../../actions/api.getCounter.js';
import ProfileCounterSkeleton from './profileCounterSkeleton.js';
import counterValue from '../../utils/counterValue.js';
import ManualCounter from '../manualCounter/manualCounter.js';
import isTable from "../../utils/isTable";

const ProfileCounter = ({
  className,
  dispatch,
  loading,
  personalAccountNumber,
  month,
  year,
  totalHotWater,
  totalColdWater,
  totalElectricity,
  totalHeating,
  objectType,
}) => {
  useEffect(() => {
    dispatch(getCounter(personalAccountNumber, objectType));
  }, [personalAccountNumber]);

  const elementClasses = classNames('profile-counter', className);
  const date = moment([year, month - 1, 1]).format('MMMM, YYYY');

  const renderBox = () => {
    if (NOT_RESIDENTIAL_TYPE.find((i) => i === objectType)) {
      return (
        <div className="profile-counter__box-empty">
          <div className="profile-counter__box-empty-icon" />
          <div className="profile-counter__box-empty-text regular-font">
            Индивидуальные приборы учета для данного объекта не предусмотрены
          </div>
        </div>
      );
    }

    if (loading) {
      return <ProfileCounterSkeleton />;
    }

    if (!totalHotWater && !totalColdWater && !totalElectricity && !totalHeating) {
      if (NOT_RESIDENTIAL_TYPE.find((i) => i === objectType)) {
        return (
          <Fragment>
            <div className="profile-counter__box-empty">
              <div className="profile-counter__box-empty-data-icon" />
              <div className="profile-counter__box-empty-text regular-font">
                Показания счетчиков отсутствуют
              </div>
            </div>
            <div className="profile-counter__link-box">
              <Link to="/profile/history">
                Все показания счетчиков
              </Link>
            </div>
          </Fragment>
        );
      }
    }

    return (
      <Fragment>
        <div className="profile-counter__box">
          <div className="profile-counter__title-box regular-font">
            <p className="profile-counter__title">{date}</p>
          </div>

          <div className="profile-counter__delimiter regular-font">
            Ручные показатели
          </div>

          <div className="profile-counter__line">
            <div className="profile-counter__line-title profile-counter__line-title--hot">Горячая вода</div>
            <div className="profile-counter__line-value profile-counter__line-value--spacing regular-font">
              {counterValue({ value: totalHotWater, afterComa: 3, beforeComa: 5 })}
              &nbsp;m3
            </div>
          </div>

          <div className="profile-counter__line">
            <div className="profile-counter__line-title profile-counter__line-title--cold">Холодная вода</div>
            <div className="profile-counter__line-value profile-counter__line-value--spacing regular-font">
              {counterValue({ value: totalColdWater, afterComa: 3, beforeComa: 5 })}
              &nbsp;m3
            </div>
          </div>

          <div className="profile-counter__delimiter regular-font">
            Автоматические показатели
          </div>

          <div className="profile-counter__line">
            <div className="profile-counter__line-title profile-counter__line-title--electric">Электроэнергия</div>
            <div className="profile-counter__line-value regular-font">
              {counterValue({ value: totalElectricity, afterComa: 2 })}
              &nbsp;кВт/ч
            </div>
          </div>

          <div className="profile-counter__line">
            <div className="profile-counter__line-title profile-counter__line-title--heating">Отопление</div>
            <div className="profile-counter__line-value regular-font">
              {counterValue({ value: totalHeating, afterComa: 2 })}
              &nbsp;Гкал
            </div>
          </div>
          <div className="profile-counter__delimiter" />
        </div>
        <div className="profile-counter__link-box">
          <Link to="/profile/history">
            Все показания счетчиков
          </Link>
        </div>
      </Fragment>
    );
  };

  return (
    <div className={elementClasses}>
      <div className="profile-info__box-title">
        {
          isTable()
            ? null
            : <div className="profile-info__title">Показания счетчиков</div>
        }
        <ManualCounter />
      </div>
      {renderBox()}
    </div>
  );
};

ProfileCounter.propTypes = {
  className: PropTypes.string,
};

ProfileCounter.defaultProps = {
  className: 'col-12',
};

const mapStateToProps = ({ clientData, counter }) => ({
  personalAccountNumber: clientData.getIn(['currentDependency', 'PersonalAccount', 'Number']),
  objectType: clientData.getIn(['currentDependency', 'PersonalAccount', 'EstateObject', 'SubType']),
  loading: counter.get('loading'),
  year: counter.getIn(['current', 'year']),
  month: counter.getIn(['current', 'month']),
  totalHotWater: counter.getIn(['current', 'total_hot_water']),
  totalColdWater: counter.getIn(['current', 'total_cold_water']),
  totalElectricity: counter.getIn(['current', 'total_electricity']),
  totalHeating: counter.getIn(['current', 'total_heating']),
});

export default connect(mapStateToProps)(ProfileCounter);
